import React, { Component } from 'react';
import { Link } from "gatsby";
import classNames from 'classnames';
import { grey } from "material-colors";
import { Icon } from "@blueprintjs/core";
// import { Drawer } from "@blueprintjs/core";
import { withTranslation } from 'react-i18next';

// Custom imports
import { MainMenuDesktop, MainMenuMobile } from './main-menu';
import biuwerLogo from '../../../static/img/biuwer-logo-amber-blue.svg';
// import LanguageSwitcher from './language-switcher';
import NavToolsSection from './nav-tools';

const BIUWER_BLUE_COLOR = '#012e67';

class HeaderNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobileMenuOpen: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleStickyHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleStickyHeader);
    }

    handleStickyHeader = () => {

        // Get the header wrapper
        const headerNavWrapper = document.getElementById("header-nav-wrapper");

        // Get the offset scroll position of the headerNavWrapper
        const headerNavWrapperOffset = headerNavWrapper.offsetHeight;

        if (window.pageYOffset > headerNavWrapperOffset + 15) {
            headerNavWrapper.classList.add("shadow");

            // Set value of padding for apply it on .mainContainer
            // this.props.setPaddingTopWhenStickyMenu(headerNavWrapperOffset);
        } else {
            headerNavWrapper.classList.remove("shadow");

            // Set value of padding for apply it on .mainContainer
            // this.props.setPaddingTopWhenStickyMenu(0);
        }
    }


    collapseMenu = () => {
        this.setState({ isMobileMenuOpen: false });
    }

    render() {
        const { t, i18n, getUrlToRedirect } = this.props,
            { isMobileMenuOpen } = this.state;

        // Styles
        const styles = {
            headerNavWrapper: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                backgroundColor: 'white',
                transition: 'all 0.4s',
                zIndex: 19
            },
            headerNav: {
                paddingTop: '10px',
                paddingBottom: '10px'
            },
            logoContainer: {
                marginLeft: '-10px'
            },
            navbarWrapper: {
                position: 'relative',
                marginBottom: isMobileMenuOpen ? '15px' : 'inherit',

                ":after": {
                    content: '" "',
                    display: isMobileMenuOpen ? 'none' : 'block',
                    height: '20px',
                    width: '2px',
                    background: grey[400],
                    position: 'absolute',
                    top: '6px',
                    right: 0,
                    pointerEvents: 'none'
                }
            },
            linksContainer: {
                display: 'flex',
                justifyContent: 'flex-end'
            },
            headerLink: {
                marginRight: '15px',
                paddingTop: '5px',
                paddingBottom: '5px',
                fontSize: '15px',
                color: BIUWER_BLUE_COLOR,
                textDecoration: 'none',
                cursor: 'pointer',
                letterSpacing: '0.65px',
                fontWeight: 500,
                backgroundColor: 'transparent',
                '&:hover': {
                    color: grey[600],
                    borderColor: grey[600],
                    backgroundColor: 'white',
                }
            },
            headerLinkWhenIsMobileMenuOpen: {
                marginRight: '30px',
                marginBottom: isMobileMenuOpen ? '15px' : 'inherit',
                fontSize: '15px',
                color: grey[800],
                textDecoration: 'none',
                cursor: 'pointer',
                letterSpacing: '1px',
                fontWeight: 600,
                backgroundColor: 'transparent',

                '&:hover': {
                    color: grey[600],
                    borderColor: grey[600],
                    backgroundColor: 'transparent'
                }
            },

            

            navbarToggler: {
                padding: '.25rem .75rem',
                backgroundColor: 'transparent',
                '&:hover': {
                    cursor: 'pointer'
                }
            },

            linkLanguageSwitcher: {
                marginRight: '30px',
                fontSize: '15px',
                color: BIUWER_BLUE_COLOR,
                textDecoration: 'none',
                cursor: 'pointer',
                letterSpacing: '1px',

                '&:hover': {
                    color: grey[600]
                }
            },
            navToolsSection: {
                position: 'relative',
                display: isMobileMenuOpen ? 'block' : 'flex',
                marginLeft: isMobileMenuOpen ? 0 : '15px',
                flexWrap: isMobileMenuOpen ? 'wrap' : 'no-wrap',
                alignItems: 'center',
            },
            languageSwitcherWrapper: {
                marginRight: '15px',
                marginBottom: isMobileMenuOpen ? '15px' : 'inherit'
            },
            linkDropDown: {
                marginLeft: '25px',
                marginTop:'10px',
                marginBottom:'-10px',
                textDecoration: 'none',
                cursor: 'pointer',
                color: grey[800],
                '&:hover':{
                    color: grey[600],
                    textDecoration: 'none',
                    cursor: 'pointer'
                }
            }
        };

        return (
            <nav
                id="header-nav-wrapper"
                css={styles.headerNavWrapper}
            >
                <div
                    id="header-nav"
                    className={
                        classNames(
                            'container',
                            'navbar',
                            'navbar-expand-lg'
                        )
                    }
                >
                    <Link
                        id="logoContainer"
                        to={i18n.language === 'en' ? '/' : '/es/'}
                        onClick={this.collapseMenu}
                        css={styles.logoContainer}
                    >
                        <img
                            src={biuwerLogo}
                            alt={t('siteTitle')}
                            width='175px'
                        />
                    </Link>
                    <Icon
                        id="navbarToggler"
                        icon="menu"
                        iconSize={30}
                        color={ BIUWER_BLUE_COLOR }
                        className="d-lg-none"
                        css={styles.navbarToggler}
                        onClick={() => this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen })}
                    />
                    <div
                        className={classNames(
                            'collapse',
                            'navbar-collapse',
                            {'show': this.state.isMobileMenuOpen}
                        )}
                        style={{
                            justifyContent: 'flex-end'
                        }}
                    >
                        <MainMenuDesktop 
                            collapseMenu={this.collapseMenu} 
                            i18n={i18n}
                        />
                        <NavToolsSection 
                            collapseMenu={this.collapseMenu} 
                            t={t}
                            i18n={i18n}
                            isMobileMenuOpen={isMobileMenuOpen}
                            getUrlToRedirect={getUrlToRedirect}
                        />
                    </div>
                </div>
                <MainMenuMobile 
                    collapseMenu={this.collapseMenu} 
                    t={t}
                    i18n={i18n}
                    isMobileMenuOpen={isMobileMenuOpen}
                    getUrlToRedirect={getUrlToRedirect}
                />
                {/* <Drawer
                    isOpen={isMobileMenuOpen}
                    position="left"
                    size="80%"
                    onClose={ () => this.setState({isMobileMenuOpen: false})}
                >
                    <div style={{
                        position: 'relative',
                        width: '100%'
                    }}>
                        <Icon
                            icon="cross"
                            iconSize={30}
                            color={ grey[800]}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '10px'
                            }}
                            onClick={ () => this.setState({isMobileMenuOpen: false})}
                        />
                    </div>

                    <div className="pl20 m30">
                        
                        <div css={styles.navToolsSection}>
                            <div css={styles.languageSwitcherWrapper}>
                                <LanguageSwitcher
                                    isMobileMenuOpen={isMobileMenuOpen}
                                    getUrlToRedirect={getUrlToRedirect}
                                />
                            </div>
                            <div>
                                <a
                                    href="https://app.biuwer.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-sm"
                                    css={isMobileMenuOpen ? styles.headerLinkWhenIsMobileMenuOpen : styles.headerLink}
                                >
                                    {this.props.t("mainMenuLinks.login")}
                                </a>
                            </div>
                            <div>
                                <Link
                                    to={ i18n.language === 'es' ? '/es/reserva-demo/' : '/en/book-demo/' }
                                    onClick={ () => {
                                        this.collapseMenu();
                                    }}
                                    role="link"
                                    className="btn btn-sm btn-primary mb15"
                                    css={ styles.requestDemoButton}
                                >
                                    {this.props.t("mainMenuLinks.requestDemo")}
                                </Link>
                            </div>
                            <div>
                                <a
                                    href={`https://app.biuwer.com/signup?language=${i18n.language || 'en'}`}
                                    target="_blank"
                                    onClick={ () => {
                                        this.collapseMenu();
                                    }}
                                    rel="noopener noreferrer"
                                    className="btn btn-sm btn-success"
                                    css={ styles.requestDemoButton}
                                >
                                    {this.props.t("mainMenuLinks.tryForFree")}
                                </a>
                            </div>
                        </div>
                    </div>
                </Drawer> */}
            </nav>
        );
    }
}

export default withTranslation()(HeaderNav);

