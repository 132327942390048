const solutionsStrings = {
    "ecommerce": {
        "name": "Biuwer for eCommerce",
        "header": {
            "title": "Do you know exactly what is happening in your online store?",
            "subtitle": "Biuwer for eCommerce is an automatic plug & play solution that helps you extract full value from your data to grow your business.",
            "quickView": "Quick view",
        },
        "whatFor": {
            "title": "What is Biuwer for eCommerce?",
            "subtitle": "Ecommerce Analytics designed to get deep into your business using the data it generates. Get automated information. Stop spending hours manually preparing data. Never miss anomalies in your data. Stay up to date on your most important metrics every day. Share knowledge and collaborate with your team. Earn more.",
            "rightTool": "Analyze data with the right tool",
            "rightToolQ1": "Are you using the right tools to grow your online business?",
                "rightToolA1": "We are usually not aware of the things we don't know, and that is why it is important to visualize and share the relevant information, beyond the main figures, in order to answer the important questions: What? Why? How?",
                "rightToolA1Bis": "The Ecommerce Analytics solution available from Biuwer is designed to get deep into your business using the data it generates, discover key points that make you sell more, have higher margins and spend less time month to month on management.",
            "rightToolQ2": "Do you use several applications (Online Store, Google Analytics, Social Media, Ads) and you can't analyze cross data from all of them?",
                "rightToolA2": "Each tool can show you the specific information of that tool, but never in a unified way with data from other applications, nor using a professional data analysis platform that allows you flexibility and unlimited evolution.",
                "rightToolA2Bis": "If every time you want to know in detail what has happened in various tools, you find yourself exporting data to Excel and then doing manual calculations, you are interested in reading on.",
            "saveTime": "Save time",
            "saveTimeQ1": "How long does it take you to prepare data from different tools to analyze them together?",
                "saveTimeA1": "One of the great advantages of using Biuwer is the time savings. Preparing data manually takes hours and requires technical knowledge, every time you want to analyze the information. With Biuwer, this is automatic, you have all the time to invest in more efficient tasks and to make decisions on a daily basis.",
            "saveTimeQ2": "Is this a barrier to you being able to make high-value decisions every day?",
            "saveTimeQ3": "What if you could have the unified data automatically available in real time?",
            "dataOwnership": "Owning my data",
            "dataOwnershipQ1": "Do you have your data owned and controlled?",
                "dataOwnershipA1": "If you do nothing, the data you generate when using applications and tools, you do not own them. Did you know that the tools you use in the cloud usually have a limited data history? This means that every day that passes you are losing a day of your data history. What if you want to analyze what has happened compared to last year? And two years ago?",
                "dataOwnershipA1Bis": "Biuwer automates the capture and consolidation of data for you, stores it in a database ready for data analysis, with all the necessary history.",
                "dataOwnershipA1Tris": "This has multiple benefits, starting with being the owner of your data. In addition to analyzing them in real time, you can export your data whenever you want without any limit to use them in other tools. You can also analyze all the information together, wherever it comes from.",
            "dataOwnershipQ2": "Did you know that the tools you use in the cloud usually have a limited data history?",
            "dataOwnershipQ3": "Did you know that every day that passes you are losing a day of your data history?",
        },
        "howWorks": {
            "title": "How does it work?",
            "subtitle": "Biuwer is a multi-channel data analytics platform that consolidates your data and provides insights into multiple aspects of your business, so you can spend your time growing your business with the best data-driven decisions.",
            "bigNumber1": "Plug & Play",
            "bigNumber1Text": "Put your online store on autopilot. Biuwer takes care of unifying your data in order to extract all its value. No installation is required as it is a 100% cloud-based platform. Sign up, configure the connection to your online store and start analyzing your data in 5 minutes.",
            "bigNumber2": "Dashboards, reports and predefined KPIs",
            "bigNumber2Text": "Create the dashboards, reports and KPIs that best suit your needs from the available templates. They are 100% customizable, so you can change and add any visualization you need.",
            "bigNumber3": "Automated Insights",
            "bigNumber3Text": "Receive periodic reports with high value insights on a regular basis. This means that by using Biuwer you will know hidden information in your data that will make you earn more money.",
            "bigNumber3TextBis": "Email reports include customizable information based on the metrics generated in Biuwer. You can receive them automatically on a weekly, monthly and quarterly basis.",
            "bigNumber4": "Share with your team",
            "bigNumber4Text": "Easily configure who can access what information. You can share dashboards and reports with the necessary people, including row-level security for the most demanding cases.",
        },
        "faqs": {
            "title": "Frequently Asked Questions",
            "q1": "Who can use Biuwer?",
            "a1": "Biuwer is designed to be used by anyone within your company, from management, eCommerce or marketing management, to anyone who needs real-time, up-to-date information to do their job every day.",
            "q2": "What is the price of Biuwer for eCommerce?",
            "a2": "Biuwer for eCommerce is included in all Biuwer plans so there is no specific price for the solution. Unlike other products, we do not charge per number of orders, nor per billing.",
            "q3": "Any examples of automatic insights?",
            "a3": "Summary of the period, with comparison with previous periods (weeks, months, quarters, years).",
            "a3Bis": "Problems detected in the main e-commerce KPIs.",
            "a3Tris": "Trend analysis.",
            "a3Cuatris": "Recommendations drawn from the data.",
            "q4": "If I need help, is there a support service?",
            "a4": "Of course, our team is available to help you through the whole process and offer support and advice. One aspect that sets Biuwer apart from other solutions is that we talk and guide users directly, with real personal support. We deal with humans.",
            "a4Bis": "Biuwer has an integrated chat for quick inquiries within the platform itself and email support is also included.",
            "a4Tris": "The Professional and Enterprise plans include more personalized support with at least one monthly report, with review via videoconference or phone call. These monthly meetings are used to review the results obtained (comparative with the rest, outliers alert, etc.) and plan improvement points to bill more and spend less, spending the minimum time necessary.",
        },
        "benefits": {
            "title": "What benefits do I get from using Biuwer?",
            "b1Title": "Automate data processing",
            "b1Text": "100% automation",
            "b2Title": "Save time month by month",
            "b2Text": "48 days a year to be more productive",
            "b3Title": "Own all your data",
            "b3Text": "Unlimited historical data",
            "b4Title": "Combining data in a simple way",
            "b4Text": "Between any type of connections",
            "b5Title": "Share information with those who need it",
            "b5Text": "Very easy and with just a few clicks",
            "b6Title": "Increase your sales",
            "b6Text": "ROI from 20% to +1000% per year, it's up to you!",
        },
        "requestDemoEcommerce": {
            "msg1": "What is not measured, cannot be improved",
            "msg2": "Learn more about Biuwer for eCommerce",
        },
    }
};

export default solutionsStrings;