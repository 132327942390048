const aboutUsStrings = {
    "vision": "Nuestra visión",
    "visionMessage": "Capacitar a las empresas de las herramientas adecuadas para que sus clientes, usuarios y partes interesadas tomen decisiones basadas en datos.",
    "mission": "Misión",
    "missionMessage1": "En Biuwer creamos y comercializamos una plataforma de analítica de datos que facilita a cualquier empresa diseñar e integrar visualizaciones dentro de sus aplicaciones de forma rápida, visual e intuitiva.",
    "missionMessage2": "Somos una startup tecnológica, ubicada en Murcia (España), fundada en 2022 por los hermanos Alberto y José Miguel Morales. Contamos con una gran experiencia en el ámbito de la analítica de datos y desarrollo de plataformas cloud.",
    "missionMessage3": "Creemos que las empresas tienen el deber de entregar datos a sus grupos de interés (clientes, usuarios, terceros, etc.) de una manera visual y fácilmente comprensible.",
    "team": "Conoce al equipo",
    "location": "Ubicación"

};

export default aboutUsStrings;