const solutionsStrings = {
    "ecommerce": {
        "name": "Biuwer for eCommerce",
        "header": {
            "title": "¿Sabes qué está pasando con exactitud en tu tienda online?",
            "subtitle": "Biuwer for eCommerce es una solución plug & play automática que te ayuda a extraer todo el valor de tus datos para hacer crecer tu negocio.",
            "quickView": "Vista rápida",
        },
        "whatFor": {
            "title": "¿Para qué sirve Biuwer for eCommerce?",
            "subtitle": "Ecommerce Analytics diseñado para llegar a lo más profundo de tu negocio usando los datos que genera. Obtén información automatizada. Deja de pasar horas preparando manualmente datos. No te pierdas nunca anomalías en tus datos. Manténte actualizado sobre tus métricas más importantes todos los días. Comparte conocimientos y colabora con tu equipo. Gana más.",
            "rightTool": "Analizar datos con la herramienta adecuada",
            "rightToolQ1": "¿Utilizas las herramientas adecuadas para poder hacer crecer tu negocio online?",
                "rightToolA1": "Normalmente no somos conscientes de las cosas que no sabemos, y por eso es importante visualizar y compartir la información relevante, más allá de las cifras principales, para poder responder a las preguntas importantes. ¿Qué? ¿Por qué? ¿Cómo?.",
                "rightToolA1Bis": "La solución de Ecommerce Analytics disponible en Biuwer está diseñada para llegar a lo más profundo de tu negocio usando los datos que genera, descubrir puntos claves que te hagan vender más, tener mayores márgenes y dedicar menos tiempo mes a mes a la gestión.",
            "rightToolQ2": "¿Utilizas varias aplicaciones (Tienda online, Google Analytics, Redes Sociales, Ads) y no puedes analizar los datos cruzados de todas ellas?",
                "rightToolA2": "Cada herramienta puede mostrarte la información específica de dicha herramienta, pero nunca de forma unificada con datos de otras aplicaciones. Usar una plataforma de análisis de datos profesional te permite flexibilidad y evolucionar sin límites a medida que tu negocio crece.",
                "rightToolA2Bis": "Si cada vez que quieres saber en detalle qué ha pasado en varias herramientas, te encuentras exportando datos a Excel y luego haciendo cálculos manuales, te interesa seguir leyendo.",
            "saveTime": "Ahorrar tiempo",
            "saveTimeQ1": "¿Cuánto tiempo tardas en preparar los datos de diferentes herramientas para analizarlos conjuntamente?",
                "saveTimeA1": "Una de las grandes ventajas de usar Biuwer es el ahorro de tiempo. Preparar los datos manualmente lleva horas y requiere conocimiento técnico, cada vez que se quiere analizar la información. Con Biuwer, esto es automático, tienes todo el tiempo para invertirlo en tareas más eficientes y para tomar decisiones diariamente.",
            "saveTimeQ2": "¿Es esto una barrera para que puedas tomar decisiones de alto valor todos los días?",
            "saveTimeQ3": "¿Y si pudieras disponer de los datos unificados automáticamente en tiempo real?",
            "dataOwnership": "Ser propietario de mis datos",
            "dataOwnershipQ1": "¿Tienes tus datos en propiedad y bajo control?",
                "dataOwnershipA1": "Si no haces nada, los datos que generas al usar aplicaciones y herramientas, no los tienes en propiedad. ¿Sabías que las herramientas que usas en la nube habitualmente tienen un histórico de datos acotado? Esto quiere decir que cada día que pasa estás perdiendo un día del histórico de tus datos. ¿Qué pasa si quieres analizar qué ha ocurrido en comparación con el año pasado? ¿Y hace dos años?",
                "dataOwnershipA1Bis": "Biuwer automatiza la captura y consolidación de los datos por tí, los almacena en una base de datos preparada para el análisis de datos, con todo el histórico necesario.",
                "dataOwnershipA1Tris": "Esto tiene múltiples beneficios, empezando por ser el propietario de tus datos. Además de analizarlos en tiempo real, cuando quieras tus datos puedes exportarlos sin ningún límite para usarlos en otras herramientas. También puedes analizar toda la información conjuntamente, provenga de donde provenga.",
            "dataOwnershipQ2": "¿Sabías que las herramientas que usas en la nube habitualmente tienen un histórico de datos acotado?",
            "dataOwnershipQ3": "¿Sabías que cada día que pasa estás perdiendo un día del histórico de tus datos?",
        },
        "howWorks": {
            "title": "¿Cómo funciona?",
            "subtitle": "Biuwer es una plataforma de análisis de datos multicanal que consolida tus datos y proporciona insights en múltiples aspectos de tu negocio, para que puedas dedicar tu tiempo a hacer crecer tu negocio con las mejores decisiones basadas en datos.",
            "bigNumber1": "Plug & Play",
            "bigNumber1Text": "Pon tu tienda online en piloto automático. Biuwer se encarga de unificar tus datos para poder extraer todo su valor. No es necesaria instalación al ser una plataforma 100% en la nube. Date de alta, configura la conexión a tu tienda online y empieza a analizar tus datos en 5 minutos.",
            "bigNumber2": "Dashboards, informes y KPIs predefinidos",
            "bigNumber2Text": "Crea los dashboards, informes y KPIs que mejor se adapten a tus necesidades, a partir de las plantillas disponibles. Son 100% personalizables, por lo que podrás cambiar y añadir cualquier visualización que necesites.",
            "bigNumber3": "Insights automáticos",
            "bigNumber3Text": "Recibe informes periódicos con insights de alto valor de forma periódica. Esto quiere decir que usando Biuwer conocerás información oculta en tus datos que te harán ganar más dinero.",
            "bigNumber3TextBis": "Los informes por correo electrónico incluyen información personalizable basada en las métricas generadas en Biuwer. Puedes recibirlos automáticamente de forma semanal, mensual y trimestral.",
            "bigNumber4": "Comparte con tu equipo",
            "bigNumber4Text": "Configura fácilmente quién puede acceder a qué información. Podrás compartir dashboards e informes con las personas necesarias, incluyendo seguridad a nivel de fila para los casos más exigentes.",
        },
        "faqs": {
            "title": "Preguntas frecuentes",
            "q1": "¿Quién puede usar Biuwer?",
            "a1": "Biuwer está diseñado para que lo pueda usar cualquier persona dentro de tu empresa, desde la gerencia, la dirección de eCommerce o de marketing, hasta cualquier persona que necesite información actualizada en tiempo real para realizar su trabajo cada día.",
            "q2": "¿Cuál es el precio de Biuwer for eCommerce?",
            "a2": "Biuwer for eCommerce está incluido en todos los planes de Biuwer por lo que no tiene un precio concreto de la solución. Al contrario que otros productos, no cobramos por número de pedidos, ni por facturación.",
            "q3": "¿Algunos ejemplos de insights automáticos?",
            "a3": "Resumen del periodo, con comparativa con periodos anteriores (semanas, meses, trimestres, años).",
            "a3Bis": "Problemas detectados en los principales KPIs de comercio electrónico.",
            "a3Tris": "Análisis de tendencias.",
            "a3Cuatris": "Recomendaciones extraídas de los datos.",
            "q4": "Si necesito ayuda, ¿hay un servicio de soporte?",
            "a4": "Por supuesto, nuestro equipo está disponible para ayudarte durante todo el proceso y ofrecerte soporte y asesoramiento. Un aspecto que diferencia a Biuwer de otras soluciones es que hablamos y guiamos directamente a los usuarios, con un soporte personal real. Tratamos con humanos.",
            "a4Bis": "Biuwer dispone de un chat integrado para consultas rápidas dentro de la propia plataforma y también se incluye un soporte vía email.",
            "a4Tris": "En los planes Profesional y Empresarial se incluye soporte más personalizado con al menos un reporte mensual, con revisión mediante videoconferencia o llamada telefónica. Estas reuniones mensuales sirven para revisar los resultados obtenidos (comparativo con resto, alerta de outliers, etc.) y planificar puntos de mejora para facturar más y gastar menos, dedicando el mínimo tiempo necesario.",
        },
        "benefits": {
            "title": "¿Qué beneficios consigo usando Biuwer?",
            "b1Title": "Automatizar procesos de datos",
            "b1Text": "100% automatización",
            "b2Title": "Ahorrar tiempo mes a mes",
            "b2Text": "48 días al año para ser más productivos",
            "b3Title": "Tener en propiedad todos tus datos",
            "b3Text": "Histórico de datos sin límite de tiempo",
            "b4Title": "Combinar datos de manera sencilla",
            "b4Text": "Entre cualquier tipo de conexiones",
            "b5Title": "Compartir información con quien la necesita",
            "b5Text": "Muy fácil y con pocos clics",
            "b6Title": "Incrementar tus ventas",
            "b6Text": "ROI desde 20% hasta +1000% anual, ¡depende de tí!",
        },
        "requestDemoEcommerce": {
            "msg1": "Lo que no se mide, no se puede mejorar",
            "msg2": "Más información sobre Biuwer for eCommerce",
        },
    }
};

export default solutionsStrings;