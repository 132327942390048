const aboutUsStrings = {
    "vision": "Our vision",
    "visionMessage": "Empower companies with the right tools to enable their customers, users and stakeholders to make data-driven decisions.",
    "mission": "Mission",
    "missionMessage1": "At Biuwer we create and market a data analytics platform that makes it easy for any company to design and integrate visualizations into their applications in a fast, visual and intuitive way.",
    "missionMessage2": "We are a technology startup, located in Murcia (Spain), founded in 2022 by brothers Alberto and José Miguel Morales. We have extensive experience in the field of data analytics and development of cloud platforms.",
    "missionMessage3": "We believe that companies have a duty to deliver data to their stakeholders (customers, users, third parties, etc.) in a visual and easily understandable way.",
    "team": "Meet the team",
    "location": "Location"
};

export default aboutUsStrings;