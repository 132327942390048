import React from 'react';
import CompanyPopoverContent from './drop-down-popover-contents/company-popover-content';
import DropDownMenuItem from './drop-down-menu-item';
import DropDownMenuItemMobile from './drop-down-menu-item-mobile';
import MenuItem from './menu-item';

function setMenuData(isMobileMenuOpen) {
    const menuData = [
        {
            id: 'productLink',
            displayName: {
                en: 'Product',
                es: 'Producto'
            },
            url: {
                en: '/en/product/',
                es: '/es/producto/'
            },
            isDropDownMenu: false,
            dropDownMenuContent: null
        },
        {
            id: 'servicesLink',
            displayName: {
                en: 'Services',
                es: 'Servicios'
            },
            url: {
                en: '/en/services/',
                es: '/es/servicios/'
            },
            isDropDownMenu: false,
            dropDownMenuContent: null
        },
        {
            id: 'pricingLink',
            displayName: {
                en: 'Pricing',
                es: 'Precios'
            },
            url: {
                en: '/en/pricing/',
                es: '/es/precios/'
            },
            isDropDownMenu: false,
            dropDownMenuContent: null
        },
        {
            id: 'blogLink',
            displayName: {
                en: 'Blog',
                es: 'Blog'
            },
            url: {
                en: '/en/blog/',
                es: '/es/blog/'
            },
            isDropDownMenu: false,
            dropDownMenuContent: null
        },
        {
            id: 'companyLink',
            displayName: {
                en: 'Company',
                es: 'Empresa'
            },
            isDropDownMenu: true,
            dropDownMenuContent: <CompanyPopoverContent isMobileMenuOpen={isMobileMenuOpen} />
        },
    ];

    return menuData;
}

const MainMenu = ({isMobileMenuOpen, collapseMenu, i18n}) => {

    const menuData = setMenuData(isMobileMenuOpen);
    
    return (
        <>
            {menuData.map(item => {
                if (item.isDropDownMenu) {
                    const displayName = item.displayName[i18n.language] || item.displayName.en;
                    if (!!isMobileMenuOpen) {
                        return <DropDownMenuItemMobile
                            key={`${item.id}-mobile`}
                            displayName={displayName}
                            dropDownMenuContent={item.dropDownMenuContent}
                        />
                    } else {
                        return <DropDownMenuItem
                            key={item.id}
                            displayName={displayName}
                            dropDownMenuContent={item.dropDownMenuContent}
                        />
                    }
                } else if(!item.isDropDownMenu) {
                    return (
                        <MenuItem
                            key={item.id}
                            collapseMenu={collapseMenu}
                            displayName={item.displayName[i18n.language] || item.displayName.en}
                            url={item.url[i18n.language] || item.url.en}
                        />
                    );
                } else {
                    return null;
                }
            })}
        </>
    );
}

export default MainMenu;