import React from 'react';
import { Link } from "gatsby";
import { grey } from "material-colors";
import { BIUWER_BLUE_COLOR } from './consts';

const MenuItem = (props) => {
    const { collapseMenu, displayName, url } = props;

    const styles = {
        headerLink: {
            marginRight: '15px',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '15px',
            color: BIUWER_BLUE_COLOR,
            textDecoration: 'none',
            cursor: 'pointer',
            letterSpacing: '0.65px',
            fontWeight: 500,
            backgroundColor: 'transparent',
            '&:hover': {
                color: grey[600],
                borderColor: grey[600],
                backgroundColor: 'white',
            }
        },
    };

    return (
            <li className="nav-item">
                <Link
                    to={url}
                    onClick={ () => {
                        collapseMenu();
                    }}
                    role="link"
                    className="nav-link"
                    css={styles.headerLink}
                >
                    {displayName}
                </Link>
            </li>
    );

};

export default MenuItem;