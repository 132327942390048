const homeStrings = {
    "headerSection": {
        "msg1": "The Embedded Analytics platform for software and SaaS companies.",
        "msg2": "Show high-value insights to your users through data visualizations integrated into your application.",
        "msg3": "100% cloud-based, always up to date!",
        "msg4": "Easy to use, no coding required!",
        "msg5": "Free 30 day trial. No credit card needed"
    },
    "meetBiuwerSection": {
        "title": "Meet one of the world's most flexible data analytics platforms",
        "description": "Biuwer helps people see and understand data to take proactive actions that come with multiple benefits: improve processes, save time, have more sales, etc.",
        "trustMsg": "Organizations of all sizes trust Biuwer in their path to be more data-driven.",
    },
    "whyBiuwerSection": {
        "title": "Why Biuwer?",
        "description": "A set of features make Biuwer unique. We like to say it's a toolbox, because of its flexibility and the ability to build a data solution using different paths.",
        "productDetails": "More product details",
        "easeOfUse": "Ease of use",
        "easeOfUseMsg": "Data analysis should about <1>asking any kind o question to our data</1>, not learning how to use software. Knowing your data, you can do <3>unlimited exploration and collaborative analysis</3> with Biuwer. That’s why people love using it.",
        "dataUsedEveryone": "Data should be used by everyone",
        "dataUsedEveryoneMsg": "One of Biuwer majors objectives is democratize data analytics. That's why <1>all our paid plans include unlimited basic users</1>, so anyone can analyze the data they need, 24x7 from anywhere.",
        "onePlatform": "One platform, many use cases",
        "onePlatformMsg": "Biuwer includes a <1>Data Center that unifies and simplifies data management</1>. Then any content that uses data can be securely accessed by several use cases: <3>internal analytics, embedded analytics, API analytics, all in one data solution</3>, etc.",
        "saveTimeMoney": "Save time, earn more money",
        "saveTimeMoneyMsg": "We strongly believe in <1>data driven growth</1>. Using Biuwer in a daily basis helps you make intelligent decisions with data. <3>Automate your data and achieve a very high ROI!.</3>",
        "cloudBased": "Cloud based",
        "cloudBasedMsg": "Use Biuwer from any device and from any web browser, 24 hours a day. No need to install anything, automatically updated to the latest features.",
        "noCodingRequired": "No coding required",
        "noCodingRequiredMsg": "Biuwer is user friendly, you don't have to be a data analyst or have a technical background with SQL knowledge to use it.",
        "customizable": "Customizable",
        "customizableMsg": "Biuwer Data Engineers and our partner network can implement Custom Cards such as application integrations, custom charts, custom reports, custom KPI metrics, custom maps, custom forms… anything you may need.",
        "affordablePricing": "Affordable pricing",
        "affordablePricingMsg": "Avoid paying thousands of dollars in expensive licenses from day one. We have the right plan for your needs, with up to thousands of users and petabytes of data."
    },
};

export default homeStrings;