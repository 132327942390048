const pricingStrings = {
    "introduction": "A pricing plan for every need",
    "free_trial": "Free 30-days trial",
    "signup": "Signup",
    "trial": {
        "start": "Start your 30-day free trial now",
        "no_setup": "No Setup Fees",
        "no_contracts": "No Contracts",
        "cancel_anytime": "Cancel Anytime",
        "no_credit_card": "No credit card required",
        "prices_without_taxes": "Prices shown in this page do not include any taxes.",
        "vat_info": "Depending on your country, VAT or other local sales taxes may be applied at checkout.",
        "try_for_free": "Try for free!",
        "create_account": "Create account"
    },
    "most_popular": "Most popular!",
    "per_month": "per month",
    "billed_yearly": "billed yearly",
    "contact_us": "Contact us",
    "unlimited": "Unlimited",
    "choose_billing_period": "Billing period",
    "monthly": "Monthly",
    "annual": "Annual",
    "annual_reward": "2 months free!",
    "choose_currency": "Currency",
    "plans": {
        "basic": {
            "name": "Basic",
            "best_for": "Start analyzing and making decisions with data internally, without external data embedding.",
            "feature_intro": "Main features:"
        },
        "standard": {
            "name": "Standard",
            "best_for": "Deliver actionable insights to your users at any scale, quickly, visually and intuitively.",
            "feature_intro": "Includes all Basic features plus:"
        },
        "professional": {
            "name": "Professional",
            "best_for": "Automate and customize premium embedded analytics experiences with complete flexibility.",
            "feature_intro": "Includes all Standard features plus:"
        },
        "enterprise": {
            "name": "Enterprise",
            "best_for": "Complete and tailored embedded analytics platform. For enterprise-grade and agencies.",
            "designed_for": "The Enterprise plan is designed for companies with specific needs that require an initial setup, continuous support and custom maintenance.",
            "feature_intro": "Includes all Professional features plus:"
        }
    },
    "included": {
        "intro_plan": "The plan includes (*):",
        "title": "Included in all plans",
        "cards": "All types of Cards",
        "cardsMsg": "KPIs (indicators), charts (+14 types), vertical tables, cross (dynamic) tables, maps, customized cards.",
        "content": "Unlimited Content",
        "contentMsg": "There is no limit in the number or complexity of the Shared Spaces, Collections, Pages or Cards created in your Organization.",
        "dataConnections": "Unlimited Data Connections",
        "dataConnectionsMsg": "Use any of the available Data Connections, including the most used databases, APIs and files.",
        "dataObjects": "Unlimited Data Objects",
        "dataObjectsMsg": "Data Models and Datasets define your data and their relationships. Use as many Datasets as you need, and model them with any number of data contexts.",
        "dataUsage": "Unlimited data usage (temporarily free)",
        "temporarilyFree": "For a limited time, you have unlimited data usage for free.",
        "dataUsageMsg": "Biuwer follows a usage-based pricing mechanism. This means you'll only be charged for the data used every month. For a limited time, you have unlimited data usage for free.",
    },
    "promo_codes": {
        "quick_start_package": "Free Quick Start package!"
    },
    "features": {
        // Included in BASIC plan
        "basic_connectors": "Conectores Básicos",
        "upload_data_file": "Upload data from CSV/Excel",
        "export_data_file": "Export to CSV, Excel, PDF",
        "favorites": "Favorite content",
        "page_card_filters": "Page and Card filters",
        "dependent_filters": "Dependent filters",
        // Included in STANDARD plan
        "embedding": "External data embedding",
        "row_access_level": "Row access level",
        "data_blending": "Data Blending",
        "rbac": "Group and Role based access",
        "reverse_engineering": "Reverse engineering",
        "calculated_fields": "Calculated fields",
        // Included in PROFESSIONAL plan
        "professional_connectors": "Professional Connectors",
        "api_access": "Public API access",
        "custom_styles": "Custom Styles",
        "multi_language": "Multi-language data and content",
        "data_portals": "Data Portals",
        "smart_data_alerts": "Smart Data Alerts",
        // Included in ENTERPRISE plan
        "custom_connectors": "Custom Connectors",
        "custom_onboarding": "Custom Onboarding",
        "white_labeling": "White labeling",
        "dedicated_server": "Dedicated server",        
        "priority_support": "Priority support",
        "sso": "Single Sign-On (SSO)"
    },
    "products": {
        "advanced_user": "Advanced Users",
        "basic_user": "Basic Users",
        "data_connection": "Data Connections",
        "data_usage": "Data Usage"
    },
    "add_ons": "(*) Additional number of users, connectors and data volume available as add-ons.",
    "faq_title": "Frequently Asked Questions",
    "faq_intro": "These are the most common questions regarding Pricing and Biuwer main functionalities.",
    "faq_intro_2": "Please feel free to reach us with any other questions you may have.",
    "faqs": {
        "i_need_more": {
            "question": "I need more users, more connections, more data usage. Can I have more?",
            "answer": "Of course you can! The plans include a specific number of users, connections and data usage as a starting point. There are a number of add-ons available, with which you can modify the number of users, connectors, volume of data usage, etc. at any time."
        },
        "i_want_a_demo": {
            "question": "I don't know Biuwer, can I see a demonstration before I make a decision?",
            "answer": "Of course! In fact, it's the most common case. Experience tells us that our clients have more success when we offer them a product demonstration first, due to there are many functionalities and possibilities. <1>Contact us</1> and we will schedule an online demonstration where we can meet you and answer your questions."
        },
        "unlimited_meaning": {
            "question": "What does unlimited mean?",
            "answer": "Unlimited means there are no predefined limits to the number of <1>Basic Users, Data Connections, Content elements, Data Objects or the Data Usage</1> your Organization consumes monthly. Of course, real limits exist, but they are quite high, so we'll only limit those Organizations whose usage is far above the average in the Biuwer ecosystem."
        },
        "signup_howto": {
            "question": "How does the registration work?",
            "answer": "Simply follow the steps we have indicated to set up your Biuwer organization. If you prefer that we contact you, <1>contact us</1> and we will help you in the process.",
            "answer_2": "New registrations start with a <1>trial period</1>. When you register, you do not need to indicate which plan you want to use, nor do you need to set up a payment method like a credit or debit card."
        },
        "trial_howto": {
            "question": "How does the trial period work?",
            "answer": "When you register you have 30 days to try Biuwer and you will start with all the available features. Take advantage of this period to contact our team who will help you to set up and make use of the platform."
        },
        "extend_trial": {
            "question": "Can I extend the trial period?",
            "answer": "Yes, in some cases we extend the trial period, according to your use and requirements. Contact us from the online support to set up a customized trial period according to your needs."
        },
        "payment_howto": {
            "question": "When do I get charged for the selected plan?",
            "answer": "As part of the confirmation for regular use of Biuwer, you will have to select an initial plan for your organisation, which will take effect after the trial period.",
            "answer_2": "You will be charged at the beginning of your billing cycle, which can be every month or every year, always on the same day. For example, if your subscription starts on April 12th, you will be charged on the 12th of each month, or on April 12th of each year, while your subscription is active."
        },
        "annual_advantages": {
            "question": "What are the advantages of contracting an annual plan?",
            "answer": "If you choose any plan with annual payment in advance, you benefit from 2 free months applied instantly on your bill. In addition, if you contract the <1>Professional Plan</1>, we give you a <3>Quick Start Package</3> for free to set up your organization in Biuwer with our data engineers."
        },
        "payment_methods": {
            "question": "What payment methods are accepted?",
            "answer": "You can pay with your credit or debit card (Visa and Master Card), direct debit (SEPA, for Europe only) and wire transfer. You will be able to choose between these payment methods when you confirm your purchase, depending on your country and the chosen billing cycle, annual or monthly."
        },
        "get_invoices": {
            "question": "How can I get invoices for the contracted service?",
            "answer": "Biuwer is designed as a service for businesses and we always generate an invoice with your details every time we charge you. You have access to your invoices from the Biuwer administration area and you can also configure it for invoices to be sent by email to the addresses you specify."
        },
        "secure_payments": {
            "question": "Are the payments secure?",
            "answer": "Biuwer uses <1>Stripe</1> as its payment provider, the world's reference platform for online payment processing and one of the most secure and reliable global payment gateways."
        },
        "change_plan": {
            "question": "Can I change my plan or my billing period later?",
            "answer": "Yes, you can switch plans at any time. The new plan will come into effect instantly. You will be charged (if you decide to upgrade) or your balance will be increased (if you decide to downgrade) pro rata for the remaining time until your next renewal date, after which you will be charged the regular price."
        },
        "cancel_subscription": {
            "question": "Can I cancel my subscription at any time?",
            "answer": "Yes, you can cancel your subscription at any time. There are absolutely no obligations. If you decide to cancel your subscription, you will lose access at the end of the current billing period."
        },
        "professional_services": {
            "question": "I want everything configured and working, do you offer professional services?",
            "answer": "Yes, Biuwer's team of data engineers and our network of partners can work with you to get everything up and running. These professional services include any work required to connect and prepare your data, automate your treatment and storage to accelerate data analysis. If you are interested in these services, <1>contact us</1> and we will prepare a quote for you."
        },
        "need_help": {
            "question": "What do I do if I need help?",
            "answer": "If you need help, we provide various contact mechanics for the Biuwer team can to assist you.",
            "answer_2": "<0>Interactive help, through our helpdesk</0><1>Documentation, through the product guides</1><2>Blog, with product updates, use cases, etc.</2>"
        },
        "user_types": {
            "question": "What is the difference between advanced and basic users?",
            "answer": "Advanced users can use all Biuwer functionalities, depending on how they are configured within the security and access control system. They also have a personal workspace to work with data individually.",
            "answer_2": "Basic users can only use Biuwer in query mode and do not have a personal space. This is the right type of user for mass distribution to analyze data and make decisions, without the need to manage, create or edit content."
        },
        "connection_types": {
            "question": "What databases and data sources can I use with Biuwer?",
            "answer": "Biuwer supports most of the SQL and analytics databases like MySQL, PostgreSQL, SQL Server, Oracle, BigQuery or Redshift. We also support other data sources like CSV, Excel and MongoDB. You can read the <1>full list here</1>.",
            "answer_2": "In case you need some other data connection technology that's not currently on the list, please contact us and we'll be happy to work on the integration."
        },
        "on_premise": {
            "question": "Do you offer on-premise installations?",
            "answer": "As a cloud product we do not have an on-premise version of Biuwer, but we offer the ability to run a private instance of Biuwer in a dedicated server, as part of the Enterprise plan. Please send us an email at <1>sales@biuwer.com</1>."
        }
    }
};

export default pricingStrings;