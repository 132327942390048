import React from 'react';
import { grey } from "material-colors";
import { Icon } from '@blueprintjs/core';
import { BIUWER_BLUE_COLOR } from './consts';

const DropDownMenuItemMobile = (props) => {
    const {displayName, dropDownMenuContent, displayContent} = props;

    const [isOpenDropDown, setIsOpenDropDown] = React.useState(false);

    const styles = {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
            border: 'none',
            color: BIUWER_BLUE_COLOR,
            padding: '5px 0',
            fontSize: '15px',
            fontWeight: 500,
            width: 'calc(100% - 40px)',
            margin: 'auto',
            "&:hover": {
                cursor: 'pointer',
                color: grey[800]
            }
        },
        icon: {
            color: BIUWER_BLUE_COLOR
        }
    }
    
    return (
        <li className='nav-item'>
            <div 
                key={displayName} 
                style={styles.wrapper}
                role="button" 
                onClick={() => setIsOpenDropDown(!isOpenDropDown)}
                onKeyDown={() => setIsOpenDropDown(!isOpenDropDown)}
                tabIndex={0}
            >
                {!!displayContent 
                    ? (
                        <>{displayContent}</>
                    ) 
                    : (<span>{displayName}</span>)
                }
                <Icon
                    icon={isOpenDropDown ? "caret-up" : "caret-down"}
                    iconSize={15}
                    style={styles.icon}
                />
            </div>
            {isOpenDropDown && (
                <div className='dropdown-mobile-content'>
                    {dropDownMenuContent}
                </div>
            )}
        </li>
    );
}

export default DropDownMenuItemMobile;
