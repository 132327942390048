import React from 'react';
import _ from 'lodash';

const DropDownContentWrapper = (props) => { 
    const { styles, mobileStyles } = props;

    const defaultStyles = {
        display: 'flex',
        flexDirection: 'column',
        padding: mobileStyles ? '5px 40px' : '15px',
        width: mobileStyles ? '100%' : '170px'  
    };

    const mergedStyles = _.merge(defaultStyles, styles);

    return (
        <div css={mergedStyles}>
            {props.children}  
        </div>
    );
}

export default DropDownContentWrapper;