const lpStrings = {
    "freeQuickStartPackage": {
        "title": "Pack de Inicio Rápido Gratuito de Biuwer",
        "subTitle": "¡Aprovecha esta oferta y haz crecer tu empresa!",
        "question1": "¿Sabes cómo está rindiendo tu empresa?",
        "question2": "¿A qué regiones y países expandirte?",
        "question3": "¿En qué partidas reducir costes?",
        "question4": "¿Cuáles son tus mejores vendedores?",
        "getTheMostOfYourData": "Saca todo el partido a los datos de tu empresa y deja atrás a la competencia",
        "benefit1": "Ahorra tiempo y reduce costes",
        "benefit2": "Incrementa las ventas y mejora tu rentabilidad",
        "benefit3": "Mejora la comunicación entre los miembros de la compañía",
        "imInterested": "Estoy interesado",
        "whatsIncluded": "El Pack de Inicio Gratuito incluye:",
        "included1": "Análisis de las necesidades de la compañía.",
        "included2": "Conexión con las fuentes de datos y definición de los modelos de datos (dimensiones de análisis, KPI´s, cálculos necesarios, etc.).",
        "included3": "Montaje de los primeros informes y dashboards en Biuwer, incluyendo los filtros de análisis interactivos.",
        "included4": "Configuración de seguridad de acceso, dando de alta a los usuarios clave.",
        "valuedAt": "Valorado hasta en 2.500€. ¡Gratis!",
        "plansToPurchase": "Contratando un Plan Profesional Anual o un Plan Estándar Anual",
        "onlyUntil": "Sólo hasta 30 Junio 2022",
        "onlyFor": "Sólo para las primeras 20 empresas",
        "50HoursIncluded": "Hasta 50 horas con el Plan Profesional Anual",
        "20HoursIncluded": "Hasta 20 horas con el Plan Standard Anual",
        "startNow": "¡EMPIEZA AHORA!",
        "completeForm": "Completa este formulario y te contactaremos en breve"
    },
    "aceleraPyme": {
        "title": "Biuwer, colaborador de Acelera Pyme",
        "subTitle": "La iniciativa ayuda a PYMEs y autónomos españoles a atenuar el impacto del COVID-19 en su actividad",
        "question": "¿Qué es Biuwer?",
        "biuwer_desc1": "Biuwer es tu plataforma cloud de Análisis de Datos",
        "biuwer_desc2": "Toma decisiones inteligentes con tus datos, incrementa tus ingresos y reduce tus gastos",
        "biuwer_desc3": "Basado en Cloud, sin necesidad de conocimientos técnicos, personalizable, ampliable y con un alto ROI"

    }
};

export default lpStrings;