import React from 'react';
import { Link } from "gatsby";
import { grey } from 'material-colors';
import { useTranslation } from 'react-i18next';

import DropDownContentWrapper from '../drop-down-content-wrapper';
import { BIUWER_BLUE_COLOR } from '../consts';

const CompanyPopoverContent = ({isMobileMenuOpen}) => {
    const { i18n, t } = useTranslation();

    const styles = {
        menuItem: {
            minWidth: '75px',
            color: BIUWER_BLUE_COLOR,
            fontSize: '13px',
            padding: '4px',
            outline: 'none',
            "&:hover": {
                cursor: 'pointer',
                backgroundColor: grey[100]
            }
        },
        spanText:{
            fontSize:'15px',
            color: grey[800],
            backgroundImage: "linear-gradient(currentColor, currentColor)",
            backgroundPosition: '0% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '0% 2px',
            transition: 'background-size .3s',
            '&:hover': {
                backgroundSize: '100% 2px'
            },
        },
        icon: {
            marginRight: '4px',
            color: grey[800]
        },
        language: {
            marginRight: '6px'
        }
    };

    return (
        <DropDownContentWrapper mobileStyles={isMobileMenuOpen}>
            <Link
                key="aboutUsLink"
                to={ i18n.language === 'es' ? '/es/sobre-nosotros/' : '/en/about-us/' } 
                css={styles.menuItem}                              
            > 
                <span css={styles.spanText}>{t('mainMenuLinks.about-us')}</span>
            </Link>
            <Link
                key="contactLink"
                to={ i18n.language === 'es' ? '/es/contacto/' : '/en/contact/' }
                css={styles.menuItem}
            >
                <span css={styles.spanText}>{t('mainMenuLinks.contact')}</span>
            </Link> 
        </DropDownContentWrapper>
    );
}

export default CompanyPopoverContent;