import React from 'react';
import { navigate } from "gatsby";
import { grey } from 'material-colors';
import { useTranslation } from 'react-i18next';
import { Icon, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

/** @jsx jsx */
import { jsx } from '@emotion/react';

// Components
import DropDownContentWrapper from './main-menu/drop-down-content-wrapper';
import { BIUWER_BLUE_COLOR } from "./main-menu/consts";
import DropDownMenuItemMobile from "./main-menu/drop-down-menu-item-mobile";
import Languages from './languages';

const LanguageSwitcher = (props) => {
    const { getUrlToRedirect, isMobileMenu = false } = props,
        { i18n } = useTranslation(),
        language = i18n.language;

    const styles = {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            border: 'none',
            color: BIUWER_BLUE_COLOR,
            "&:hover": {
                cursor: 'pointer',
                color: grey[800]
            }
        },
        menuItem: {
            minWidth: '75px',
            color: BIUWER_BLUE_COLOR,
            fontSize: '13px',
            padding: '4px',
            outline: 'none',
            "&:hover": {
                cursor: 'pointer',
                backgroundColor: grey[100]
            }
        },
        spanText:{
            fontSize:'15px',
            color: grey[800],
            backgroundImage: "linear-gradient(currentColor, currentColor)",
            backgroundPosition: '0% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '0% 2px',
            transition: 'background-size .3s',
            '&:hover': {
                backgroundSize: '100% 2px'
            },
        },
        icon: {
            marginRight: '4px',
            color: BIUWER_BLUE_COLOR
        },
        language: {
            marginRight: '6px'
        }
    };

    const handleClick = (lang) => {
        if(language !== lang) {
            i18n.changeLanguage(lang);
            navigate(getUrlToRedirect(lang));
        }
    }

    let popoverContent = (
        <DropDownContentWrapper mobileStyles={isMobileMenu}>
            <div
                onClick={() => handleClick('en')}
                onKeyDown={() => handleClick('en')}
                role="button"
                tabIndex={0}
                css={styles.menuItem}
            >
                <span css={styles.spanText}>English</span>
            </div>
            <div
                onClick={() => handleClick('es')}
                onKeyDown={() => handleClick('es')}
                role="button"
                tabIndex={0}
                css={styles.menuItem}
            >
                <span css={styles.spanText}>Español</span>
            </div>
        </DropDownContentWrapper>
    );

    if (isMobileMenu) {
        return (
            <DropDownMenuItemMobile
                key={"language-switch-mobile"}
                displayContent={
                    <Languages 
                        styles={styles}
                        language={language}
                    />
                }
                dropDownMenuContent={popoverContent}
            />
        )
    } else {
        return (
            <Popover2
                boundary="viewport"
                content={popoverContent}
                interactionKind={PopoverInteractionKind.HOVER}
                position={Position.BOTTOM_LEFT}
                minimal={false}
                usePortal={false}
            >
                <div css={styles.wrapper} >
                    <Languages 
                        styles={styles}
                        language={language}
                    />
                    <Icon
                        icon="caret-down"
                        iconSize={15}
                        css={styles.icon}
                    />
                </div>
            </Popover2>
        )
    }
}

export default LanguageSwitcher;
