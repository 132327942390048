import React from 'react';
import { Icon } from '@blueprintjs/core';

const Languages = ({styles, language}) => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center"
        }}>
            <Icon
                icon="globe"
                iconSize={14}
                css={styles.icon}
            />
            <span css={styles.language}>
                {language && language.toUpperCase()}
            </span>
        </div>
    )
}

export default Languages;