import React from 'react';
import { grey } from "material-colors";
import { Icon, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { BIUWER_BLUE_COLOR } from './consts';

const DropDownMenuItem = (props) => {
    const { displayName, dropDownMenuContent } = props;

    const styles = {
        headerLink: {
            marginRight: '15px',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '15px',
            color: BIUWER_BLUE_COLOR,
            textDecoration: 'none',
            cursor: 'pointer',
            letterSpacing: '0.65px',
            fontWeight: 500,
            backgroundColor: 'transparent',
            '&:hover': {
                color: grey[600],
                borderColor: grey[600],
                backgroundColor: 'white',
            }
        },
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            border: 'none',
            color: BIUWER_BLUE_COLOR,
            "&:hover": {
                cursor: 'pointer',
                color: grey[800]
            }
        },
        icon: {
            marginRight: '4px',
            color: BIUWER_BLUE_COLOR
        },
    };

    return (
        <li className="nav-item">
            <div css={styles.headerLink}>
                <Popover2
                    boundary="viewport"
                    content={dropDownMenuContent}
                    interactionKind={PopoverInteractionKind.HOVER}
                    position={Position.BOTTOM_LEFT}
                    usePortal={false}
                    modifiers={{arrow: { enabled: true}}}
                    popoverClassName="menuDropDown"
                >         
                    <div css={styles.wrapper}>
                        <span>{displayName}</span>
                        <Icon
                            icon="caret-down"
                            iconSize={15}
                            css={styles.icon}
                        />
                    </div>
                </Popover2> 
            </div>
        </li>
    );
};

export default DropDownMenuItem;