import React from 'react';
import { Drawer } from "@blueprintjs/core";
import { Icon } from "@blueprintjs/core";

// Custom imports
import MainMenu from './menu-data';
import { BIUWER_BLUE_COLOR } from './consts';
import NavToolsSection from '../nav-tools';

const MainMenuMobile = (props) => { 
    const { i18n, isMobileMenuOpen, collapseMenu, t, getUrlToRedirect } = props;

    return (
        <Drawer
            isOpen={isMobileMenuOpen}
            position="left"
            size="80%"
            onClose={collapseMenu}
        >
            <div style={{
                width: '100%',
                display: 'flex',
            }}>
                <Icon
                    icon="cross"
                    iconSize={30}
                    color={BIUWER_BLUE_COLOR}
                    style={{
                        margin: '10px 20px 10px auto'
                    }}
                    onClick={collapseMenu}
                />
            </div>
            <ul className='menu-items'>
                <MainMenu 
                    isMobileMenuOpen={isMobileMenuOpen}
                    i18n={i18n}
                    collapseMenu={collapseMenu}
                />
                <NavToolsSection 
                    isMobileMenuOpen={isMobileMenuOpen} 
                    t={t}
                    i18n={i18n}
                    collapseMenu={collapseMenu}
                    getUrlToRedirect={getUrlToRedirect}
                />
            </ul>
        </Drawer>
    );
}

export default MainMenuMobile;