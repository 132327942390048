 
const strapiUrl = "https://strapi.biuwer.com"
const strapiConfig = {
    apiURL: strapiUrl,
    accessToken: "a77825d47ca358890e3c1288c0233ccaabaf87a0158679e8df6932319841774cebb2f55e50af07f3017d6ce4dca11342c89804e98d09f8e5cdf5fcea223cfdb4b135c64ce54c6a87eadd78bac89c2117f66b7ba9d16705b2775d5ce405504b78e9aaccc673ac8517516009b830d348d82e24dba6167d06ee8729bf7a610a245c",
    collectionTypes: ['article','product-update','legal'],
    singleTypes: [],
  };


module.exports = {
    siteMetadata: {
        siteUrl: 'https://biuwer.com',
        strapiUrl: strapiUrl,
        navHeaderHeight: 90,
        defaultMetaTags: {
            imgUrl: '/img/biuwer-logo-1000w.png',
            twitter: '@biuwer',
        },
        en: {
            title: 'Biuwer - Embedded Analytics platform for software and SaaS companies.',
            description: 'Biuwer is your cloud-based Data Analytics and Business Intelligence platform. Build beautiful dashboards. No coding required!. Use a modern Data Platform to manage your entire business'
        },
        es: {
            title: 'Biuwer - Plataforma de Embedded Analytics para empresas de software y SaaS',
            description: 'Biuwer es tu plataforma cloud de Análisis de Datos y Business Intelligence. Crea bonitos cuadros de mando. ¡No es necesario tener conocimientos técnicos!. Utiliza una moderna Plataforma de Datos para gestionar toda tu organización.'
        }
    },
    plugins: [
        'gatsby-plugin-image',
        'gatsby-plugin-sharp',
        'gatsby-plugin-react-helmet',
        'gatsby-plugin-sass',
        'gatsby-plugin-sitemap',

        {
            resolve: `gatsby-source-strapi`,
            options: strapiConfig,
         },

        // Segment
        {
            resolve: 'gatsby-plugin-segment-js',
            options: {
                // your segment write key for your production environment
                // when process.env.NODE_ENV === 'production'
                // required; non-empty string
                prodKey: '4ts3xaXVadTWs8o26kFleffbu4KwZvKc',

                // if you have a development env for your segment account, paste that key here
                // when process.env.NODE_ENV === 'development'
                // optional; non-empty string
                devKey: 'aqMioqQJ6YgbbUODp4HLt8p7PJ3jX4cf',

                // whether you want to include analytics.page()
                // optional; boolean that defaults to true
                // if false, then don't forget to manually add it to your codebase manually!
                trackPage: true
            }
        },

        // Google Tag Manager
        {
            resolve: "gatsby-plugin-google-tagmanager",
            options: {
                id: "GTM-NHNVNMH",
                includeInDevelopment: true,
                enableWebVitalsTracking: true
            },
        },

        {
            resolve: 'gatsby-plugin-crisp-chat',
            options: {
              websiteId: 'fc505a68-b536-4bf7-b6af-ab072a01d806',
              enableDuringDevelop: true, // Optional. Disables Crisp Chat during gatsby develop. Defaults to true.
              defer: false, // Optional. Sets the Crisp loading script to defer instead of async. Defaults to false.
              enableImprovedAccessibility: true // Optional. Sets aria-label attribute on pop-up icon for screen readers. Defaults to true.
            },
        },

        {
            resolve: 'gatsby-transformer-remark',
            options: {
                plugins: [
                    {
                        resolve: 'gatsby-remark-images',
                        options: {
                            backgroundColor: 'transparent',
                            linkImagesToOriginal: false,
                            showCaptions: false,
                            maxWidth: 1110,
                        },
                    },
                    'gatsby-remark-copy-linked-files',
                    'gatsby-remark-smartypants',
                    'gatsby-remark-lazy-load',
                ],
            },
        },

        'gatsby-transformer-sharp',
        'gatsby-plugin-sharp',

        // Emotion css-in-js library
        {
            resolve: 'gatsby-plugin-emotion',
            options: {
                // Accepts all options defined by 'babel-plugin-emotion' plugin.
            },
        },

        {
            resolve: 'gatsby-plugin-manifest',
            options: {
                name: 'Biuwer public website',
                short_name: 'Biuwer.com',
                start_url: '/',
                background_color: '#ffffff',
                theme_color: '#ffc107',
                display: 'minimal-ui',
                icon: 'static/favicon.ico',
            },
        },

        {
            resolve: 'gatsby-plugin-robots-txt',
            options: {
                host: 'https://biuwer.com',
                env: {
                    development: {
                        policy: [{ userAgent: '*', disallow: ['/'] }]
                    },
                    production: {
                        policy: [{ userAgent: '*', allow: '/' }]
                    }
                }
            }
        },

        // Plugin to navigate to anchors --> gatsby-plugin-anchor-links
        {
            resolve: 'gatsby-plugin-anchor-links',
            options: {

                // offset --> # of pixels from top
                offset: -100
            }
        },
    ],
    flags: {
        FAST_DEV: true,
        DEV_SSR: false
    }
};
