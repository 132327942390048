const servicesStrings = {
    "mainTitle": "Services, Support and Training",
    "subTitle": "Professional services and support from Biuwer team",
    "callToActionTitle": "Your strategic data partner",
    "callToActionMsg1": "We’re with you and your team during the entire data journey",
    "callToActionMsg2": "Our goal is to take your company to the next level in the Data Driven Growth strategy",
    "talkToTheTeam": "Talk to the team",
    "imInterested": "I am interested",
    "mainObjective": "Main Objective",
    "whoIsItFor": "Who is it for?",
    "whatsIncluded": "What's included?",
    "specialOffer": "Special Offer",
    "someExamples": "Some Examples",
    "whatsTheResult": "What's the result?",
    "quickStartPackage": {
        "title": "Quick Start Package",
        "description": "Get started with a service that includes training and professional data services to get Biuwer up and running with your data quickly.",
        "mainObjective": "To launch a Data Analysis solution using Biuwer, with your data and for your key users.",
        "whoIsItFor": "This service is intended for companies that do not have the resources, experience or knowledge about databases, data models or information analysis principles to be self sufficient.",
        "whatsIncluded": "Include online sessions with your team members. In addition, a Biuwer data engineer will work to connect, automate and model your data, up to building the Cards (KPIs, graphs, tables, etc.) and Pages (panels and dashboards) with the format and data that give you the most value.",
        "includedDetail1": "A common session to detect the most important needs and those that are urgent. We discover together the available data sources and we define the next steps.",
        "includedDetail2": "We connect your data sources, and then define the data models (dimensions of analysis, key indicators and the necessary intermediate calculations).",
        "includedDetail3": "We build the first reports and dashboards in Biuwer, including interactive analysis filters.",
        "includedDetail4": "We define data access settings, by registering the key users and configuring access to the exact data they must analyze.",
        "includedDetail5": "Guided training for your team, to correctly analyze the information and get the most out of it.",
        "specialOfferTitle": "Get a Quick Start Package for free",
        "specialOfferSubtitle": "Purchasing an Annual Professional plan or an Annual Standard plan",
        "specialOfferDetail": "Get a Quick Start Package for free when purchasing an Annual Professional plan or an Annual Standard plan."
    },
    "customCardDevelopment" : {
        "title": "Custom Card Development",
        "description": "Biuwer is an extensible Data Platform that allows you to create Custom Cards, for example, graphics, maps, interactive forms or any other type of content.",
        "mainObjective": "Have one or more personalized Cards for private use in your Biuwer Organization, with the behavior and visual design you want to give it.",
        "whoIsItFor": "This service is intended for companies that do not have the resources, experience or knowledge about Javascript web development or graphics libraries like D3.js to be self sufficient using an SDK (Software Development Kit).",
        "whatsIncluded": "Both data experts and application development experts participate in this service, in order to respond to your needs. It is a a mini project that needs to be analyzed, designed, developed, tested and launched, specifically for your organization.",
        "example1": "Integrations with third party applications",
        "example2": "Custom Charts",
        "example3": "Custom Reports",
        "example4": "Custom KPIs cards",
        "example5": "Custom Maps",
        "example6": "Customized web Forms"
    },
    "customConnectorDevelopment": {
        "title": "Custom Connector Development",
        "description": "We can create custom Data Connectors on very specific applications, databases or systems that you need to link to automate the query and extraction of data for its treatment, storage and subsequent analysis.",
        "mainObjective": "To be able to access the data available in applications, databases or file stores not natively supported by Biuwer.",
        "whoIsItFor": "This service is intended for companies that have or use applications, databases or file stores with special requirements that require creating a specific connector.",
        "whatsIncluded": "Our experts in application development and data, work to understand the data structures, APIs, or the most appropriate connection m ethods, until you have a configurable connector to retrieve data from.",
        "example1": "Internal (private) applications that have an API",
        "example2": "Public applications that have an API",
        "example3": "Custom Data Warehouses or custom Files",
        "example4": "Specific databases"
    },
    "dataWarehouseAutomation": {
        "title": "Data Warehouse Automation",
        "description": "One of our recommendations to have an automated and extensible data ecosystem is to use a Data Warehouse, separated from your transactional systems (ERP, CRM, sensors, social networks, cloud applications, etc.).",
        "mainObjective": "To have a centralized, automated and optimized Data Warehouse for Data Analysis, with high performance to obtain fast results.",
        "whoIsItFor": "This service is designed for companies that bet on owning a Data Warehouse in which to integrate all their historified data of interest, with the necessary detail to answer any business question.",
        "whatsIncluded": "Our data experts work with you to integrate the data from your transactional applications and from other sources, propose a modular data design and define the automation of data (incremental loading, periodic updates, indicator calculation, etc.).",
        "whatsTheResult": "This is a personalized data project for your company, culminating in the implementation of two main elements: the Data Integration Processes (ETL / ELT) and the Storage System (to be chosen according to data volume, your preferences, needs for data analysis, etc.)."
    }
};

export default servicesStrings;