import React from 'react';
import { grey, green } from "material-colors";
import { Link } from "gatsby";

// Custom imports
import LanguageSwitcher from './language-switcher';
import { BIUWER_BLUE_COLOR } from './main-menu/consts';

const NavToolsSection = (props) => { 
    const { isMobileMenuOpen, t, i18n, collapseMenu, getUrlToRedirect } = props;

    const styles = {
        headerLink: {
            marginRight: '15px',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '15px',
            color: BIUWER_BLUE_COLOR,
            textDecoration: 'none',
            cursor: 'pointer',
            letterSpacing: '0.65px',
            fontWeight: 500,
            backgroundColor: 'transparent',
            '&:hover': {
                color: grey[600],
                borderColor: grey[600],
                backgroundColor: 'white',
            }
        },
        navToolsSection: {
            position: 'relative',
            display: isMobileMenuOpen ? 'block' : 'flex',
            marginLeft: isMobileMenuOpen ? 0 : '15px',
            flexWrap: isMobileMenuOpen ? 'wrap' : 'no-wrap',
            alignItems: 'center',
        },
        requestDemoButton: {
            marginRight: '15px',
            fontSize: '15px',
            textDecoration: 'none',
            cursor: 'pointer',
            letterSpacing: '0.65px',
            fontWeight: 500,
            borderColor: 'transparent',
            '&:hover': {
                color: 'white',
                borderColor: 'transparent',
                textDecoration: 'none',
            },
            color: 'white',
        },
        tryForFreeButton: {
            fontSize: '15px',
            textDecoration: 'none',
            cursor: 'pointer',
            letterSpacing: '0.65px',
            fontWeight: 500,
            borderColor: 'transparent',
            '&:hover': {
                color: 'white',
                borderColor: 'transparent',
                textDecoration: 'none',
                backgroundColor: green[600]
            },
            color: 'white',
            backgroundColor: green[800]
        },
    };


    return (
        <div css={styles.navToolsSection} style={!!isMobileMenuOpen ? {marginTop: '40px'} : null}>
            <div css={styles.languageSwitcherWrapper}>
                <LanguageSwitcher
                    isMobileMenu={isMobileMenuOpen}
                    getUrlToRedirect={getUrlToRedirect}
                    {...props}
                />
            </div>
            <div className={!!isMobileMenuOpen ? "nav-tools" : null}>
                <a
                    href="https://app.biuwer.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-sm btn-outline"
                    css={isMobileMenuOpen ? styles.headerLinkWhenIsMobileMenuOpen : styles.headerLink}
                >
                    {t("mainMenuLinks.login")}
                </a>
            </div>
            <div className={!!isMobileMenuOpen ? "nav-tools" : null}>
                <Link
                    to={ i18n.language === 'es' ? '/es/reserva-demo/' : '/en/book-demo/' }
                    onClick={ () => {
                        collapseMenu();
                    }}
                    role="link"
                    className="btn btn-sm btn-primary"
                    css={ styles.requestDemoButton}
                >
                    {t("mainMenuLinks.requestDemo")}
                </Link>
            </div>
            <div className={!!isMobileMenuOpen ? "nav-tools" : null}>
                <a
                    href={`https://app.biuwer.com/signup?language=${i18n.language || 'en'}`}
                    target="_blank"
                    onClick={ () => {
                        collapseMenu();
                    }}
                    rel="noopener noreferrer"
                    className="btn btn-sm btn-success"
                    css={ styles.tryForFreeButton}
                >
                    {t("mainMenuLinks.tryForFree")}
                </a>
            </div>
        </div>
    );
}

export default NavToolsSection;