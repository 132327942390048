const servicesStrings = {
    "mainTitle": "Servicios, Soporte y Formación",
    "subTitle": "Servicios profesionales y soporte por el equipo de Biuwer",
    "callToActionTitle": "Tu partner de datos estratégico",
    "callToActionMsg1": "Estamos contigo y con tu equipo durante todo el proceso de datos",
    "callToActionMsg2": "Nuestro objetivo es llevar a tu organización al siguiente nivel en la estrategia de Crecimiento Impulsado por Datos",
    "talkToTheTeam": "Habla con el equipo",
    "imInterested": "Estoy interesado",
    "mainObjective": "Objetivo principal",
    "whoIsItFor": "¿A quién va dirigido?",
    "whatsIncluded": "¿Qué está incluido?",
    "specialOffer": "Oferta Especial",
    "someExamples": "Algunos Ejemplos",
    "whatsTheResult": "¿Cuál es el resultado?",
    "quickStartPackage": {
        "title": "Paquete de Inicio Rápido",
        "description": "Comienza con una oferta empaquetada que incluye formación y servicios profesionales para que Biuwer esté en funcionamiento con tus datos rápidamente.",
        "mainObjective": "Poner en marcha una solución de Análisis de Datos usando Biuwer, con tus datos y para tus usuarios clave.",
        "whoIsItFor": "Este servicio está pensado para las empresas que no cuentan con los recursos, la experiencia o los conocimientos acerca de bases de datos, modelos de datos o principios de análisis de información para ser auto suficientes.",
        "whatsIncluded": "Incluye sesiones online con los miembros de tu equipo. Además un ingeniero de datos de Biuwer trabajará para conectar, automatizar y modelar tus datos, hasta construir las Tarjetas (KPIs, gráficas, tablas, etc.) y Páginas (paneles y cuadros de mando) con el formato y los datos que te aporten más valor.",
        "includedDetail1": "Sesión conjunta para detectar las necesidades más importantes y aquellas que son urgentes. También se conocen las fuentes de datos disponibles y se definen los siguientes pasos a dar.",
        "includedDetail2": "Conexión con las fuentes de datos, junto con la definición de los modelos de datos (dimensiones de análisis, indicadores clave, cálculos intermedios necesarios).",
        "includedDetail3": "Montaje de los primeros informes y dashboards en Biuwer, incluyendo los filtros de análisis interactivos.",
        "includedDetail4": "Configuración de la seguridad de acceso, dando de alta a los usuarios clave y configurando el acceso a los datos que deben analizar.",
        "includedDetail5": "Formación guiada para tu equipo, para analizar correctamente la información y sacarle todo el partido.",
        "specialOfferTitle": "Obtén un Paquete de Inicio Rápido de forma gratuita",
        "specialOfferSubtitle": "Al comprar un Plan Profesional Anual o un Plan Estándar Anual",
        "specialOfferDetail": "Obtén un Paquete de Inicio Rápido de forma gratuita al comprar un Plan Profesional Anual o un Plan Estándar Anual."
    },
    "customCardDevelopment" : {
        "title": "Desarrollo de Tarjetas Personalizadas",
        "description": "Podemos crear conectores de datos personalizados sobre aplicaciones, bases de datos o sistemas muy concretos, que necesites enlazar para automatizar la consulta y extracción de datos para su tratamiento, almacenamiento y análisis posterior.",
        "mainObjective": "Poder acceder a los datos disponibles en aplicaciones, bases de datos o almacenes de ficheros no soportados nativamente por Biuwer.",
        "whoIsItFor": "Este servicio está pensado para empresas que no cuentan con los recursos, la experiencia o los conocimientos acerca de desarrollo web Javascript o librerías de gráficos como D3.js para ser auto suficientes utilizando un SDK (Software Development Kit).",
        "whatsIncluded": "En este servicio participan tanto expertos de datos como en desarrollo de aplicaciones, para poder dar respuesta a tus necesidades. Lo planteamos como un mini proyecto que es necesario analizar, diseñar, desarrollar, probar y poner en marcha, específicamente para tu organización.",
        "example1": "Integraciones con aplicaciones de terceros",
        "example2": "Gráficos personalizados",
        "example3": "Informes a medida",
        "example4": "Tarjetas de KPIs personalizadas",
        "example5": "Mapas a medida",
        "example6": "Formularios web personalizados"
    },
    "customConnectorDevelopment": {
        "title": "Desarrollo de Conectores Personalizados",
        "description": "Podemos crear Conectores de Datos personalizados sobre aplicaciones, bases de datos o sistemas muy concretos, que necesites enlazar para automatizar la consulta y extracción de datos para su tratamiento, almacenamiento y análisis posterior.",
        "mainObjective": "Poder acceder a los datos disponibles en aplicaciones, bases de datos o almacenes de ficheros no soportados nativamente por Biuwer.",
        "whoIsItFor": "Este servicio está pensado para empresas que tengan o utilicen aplicaciones, bases de datos o almacenes de ficheros con unos requisitos especiales que requieran crear un conector específico.",
        "whatsIncluded": "Nuestros expertos de datos y de desarrollo de aplicaciones trabajan para comprender las estructuras de datos, las APIs o métodos de conexión más adecuados, hasta poder disponer de un conector configurable con el que obtener los datos en Biuwer.",
        "example1": "Aplicaciones internas (privadas) que dispongan de un API",
        "example2": "Aplicaciones públicas que dispongan de un API",
        "example3": "Almacenes de Datos o Ficheros personalizados",
        "example4": "Bases de Datos específicas"
    },
    "dataWarehouseAutomation": {
        "title": "Automatización de Data Warehouse",
        "description": "Una de nuestras recomendaciones para disponer de un ecosistema de datos automatizado y ampliable es utilizar un Data Warehouse separado de los sistemas transaccionales (ERP, CRM, sensores, redes sociales, aplicaciones cloud, etc.).",
        "mainObjective": "Disponer de un Almacén de Datos centralizado, automatizado y optimizado para el Análisis de Datos, con un alto rendimiento para obtener los resultados rápidos.",
        "whoIsItFor": "Este servicio está pensado para empresas que apuesten por tener en propiedad un Data Warehouse en el que integrar todos sus datos de interés, historificados, con el detalle necesario para contestar cualquier pregunta de negocio.",
        "whatsIncluded": "Nuestros expertos de datos trabajan contigo para integrar los datos de tus aplicaciones transaccionales y de otros orígenes, plantear un diseño de datos modular y definir la automatización de datos (carga incremental, actualizaciones periódicas, cálculo de indicadores, etc.).",
        "whatsTheResult": "Se trata de un proyecto de datos personalizado para tu compañía con culmina al poner en marcha dos elementos principales: los Procesos de Integración de Datos (ETL / ELT) y el Sistema de Almacenamiento (a elegir según volumetría de datos, tus preferencias, necesidades de análisis de datos, etc.)."
    }
};

export default servicesStrings;