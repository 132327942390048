import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useTranslation } from 'react-i18next';

import config from '../../../gatsby-config';

const Seo = props => {
    const { pageContext, headTitle, ogType } = props,
        { i18n } = useTranslation(),
        lang = pageContext.lang || i18n.language,
        title = headTitle || pageContext.title || config.siteMetadata[lang].title,
        description = pageContext.description || config.siteMetadata[lang].description,
        path = pageContext.slug,
        siteUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' :config.siteMetadata.siteUrl,
        imgUrl = `${siteUrl}${config.siteMetadata.defaultMetaTags.imgUrl}` ,
        twitter = config.siteMetadata.defaultMetaTags.twitter,
        url= `${siteUrl}${path}`;

    return (
        <Helmet defer={false}>
            <html lang={lang} />
            <link rel="canonical" href={url} />
            <title>{title}</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta name="description" content={description} />
            <link rel="shortcut icon" type="image/png" href="/favicon.ico" />

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imgUrl} />
            <meta property="og:type" content={ogType} />
            {/*<meta property="fb:app_id" content={facebook.appId} />*/}

            {/* Twitter Card tags  */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content={twitter} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imgUrl} />

            <link href="https://fonts.googleapis.com/css?family=Exo:300,400,500,600,700|Open+Sans:400,600" rel="stylesheet" />
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossOrigin="anonymous" />
        </Helmet>
    )
};

Seo.propTypes = {
    ogType: PropTypes.oneOf(['website', 'article', 'blog'])
};

Seo.defaultProps = {
    ogType: 'website'
};

export default Seo;