import React, { Component } from 'react';
import { find } from 'lodash';
import moment from 'moment';
import 'lazysizes';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';

// Styles imports (CSS, SCSS, etc.)
import 'normalize.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import '../styles/main.scss';

// Components
import Seo from './Seo';
import HeaderNav from './HeaderNav';
import Footer from './Footer';
import CookieConsentComponent from "./CookieConsent/CookieConsent";

class MainLayout extends Component {
    constructor(props) {
        super(props);
        this.getUrlToRedirect = this.getUrlToRedirect.bind(this);
    }

    componentDidMount() {
        const lng = i18n.language;

        // Moment locale initialising
        if (lng && moment.locales().indexOf(lng) === -1) {
            require(`moment/locale/${lng}`);
        }
        moment.locale(lng);
    }

    componentDidUpdate() {
        const lng = i18n.language;

        // Moment locale checking
        if (lng && moment.locales().indexOf(lng) === -1) {
            require(`moment/locale/${lng}`);
        }
        moment.locale(lng);
    }

    /**
     * Function that returns the url to redirect based on the user path and the language passed via parameter
     * @param lng
     * @returns {string}
     */
    getUrlToRedirect(lng) {
        let { pageContext } = this.props;
        let pathToRedirect = '/',
            pageName = pageContext.name,
            paths = pageContext.paths;

        if (pageContext.slugs) {
            let pathObject = pageContext.slugs[lng];
            if (pathObject) {
                pathToRedirect = `${pathObject}`
            }
            return pathToRedirect;
        }

        if(pageName && paths) {
            let pathObject = find(paths[lng], (item) => item.name === pageName);
            if(pathObject) {
                pathToRedirect = pathObject.path;
            }

            return pathToRedirect;
        }

        return pathToRedirect;
    }

    render() {
        const { pageContext, children } = this.props,
            pageName = pageContext.name,
            paths = pageContext.paths;

        return (
            <I18nextProvider i18n={i18n}>
                <div id="main-wrapper">
                    <Seo
                        key="seo"
                        pageContext={pageContext}
                    />

                    <CookieConsentComponent />

                    <HeaderNav
                        pageName={pageName}
                        paths={paths}
                        getUrlToRedirect={this.getUrlToRedirect}
                    />

                    <div className="mainContainer">
                        {children}
                    </div>

                    <Footer
                        getUrlToRedirect={this.getUrlToRedirect}
                    />
                </div>
            </I18nextProvider>
        );
    }
}

export default MainLayout;