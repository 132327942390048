import React  from 'react';

import logoLanzadera from '../../../static/img/entidades/logo-lanzadera.png';
import logoRedesEuropa from '../../../static/img/entidades/logo-redes-europa.png';
import logoInfo from '../../../static/img/entidades/logo-info.png';
import logoCertificadaENISA from '../../../static/img/entidades/logo-empresa-emergente-certificada-enisa.png';

const SupportedBy =  (props) => {
    let { t } = props;

    return (
        <>
            <div className="row">
                <div className='col fs14'>{t('footer.supportedBy')}</div>
            </div>
            <div className="row mb5">
                <div className='col'>
                    <img
                        alt="Lanzadera"
                        src={logoLanzadera}
                        className="mr20 mb5"
                        style={{ height: 30}}
                    />
                    <img
                        alt="Instituto Fomento Región Murcia (Spain)"
                        src={logoInfo}
                        className="mr20 mb5"
                        style={{ height: 70}}
                    />
                    <img
                        alt="Red.es - Una manera de hacer Europa"
                        src={logoRedesEuropa}
                        className="mr20 mb5"
                        style={{ height: 100}}
                    />
                    <img
                        alt="Empresa emergente certificada por ENISA"
                        src={logoCertificadaENISA}
                        className="mr20 mb5"
                        style={{ height: 100}}
                    />
                </div>
            </div>
            <div className='row fs11 mb20'>
                    <p>{t('footer.supportedByMessageXpande')}</p>
            </div>
        </>
    )

}

export default SupportedBy;