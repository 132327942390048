import i18n from "i18next"
import languageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translation_en from './locales/en/translation';
import home_en from './locales/en/home';
import services_en from './locales/en/services';
import pricing_en from './locales/en/pricing';
import lp_en from './locales/en/lp';
import legal_en from './locales/en/legal';
import demo_en from './locales/en/demo';
import solutions_en from './locales/en/solutions';
import aboutUs_en from './locales/en/about-us';

import translation_es from './locales/es/translation';
import home_es from './locales/es/home';
import services_es from './locales/es/services';
import pricing_es from './locales/es/pricing';
import lp_es from './locales/es/lp';
import legal_es from './locales/es/legal';
import demo_es from './locales/es/demo';
import solutions_es from './locales/es/solutions';
import aboutUs_es from './locales/es/about-us';

// Set languages and name spaces arrays
const languages = [ 'en', 'es' /* Add more languages here and in gatsby-node.js */];
const nameSpaces = [ 'translation', 'home', 'services', 'pricing', 'lp', 'legal', 'demo', 'solutions' /* Add more namespaces */ ];

i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            es: {
                translation: translation_es,
                home: home_es,
                services: services_es,
                pricing: pricing_es,
                lp: lp_es,
                legal: legal_es,
                demo: demo_es,
                solutions: solutions_es,
                aboutUs: aboutUs_es,
                /* Add more namespaces here */
            },
            en: {
                translation: translation_en,
                home: home_en,
                services: services_en,
                pricing: pricing_en,
                lp: lp_en,
                legal: legal_en,
                demo: demo_en,
                solutions: solutions_en,
                aboutUs: aboutUs_en,
                /* Add more namespaces here */
            },
            /* Add more languages here */
        },
        fallbackLng: "en",

        debug: process.env.NODE_ENV === 'development',

        ns: nameSpaces,

        defaultNS: 'translation',

        whitelist: languages,

        detection: {
            order: ['path']
        },

        interpolation: {
            escapeValue: false // not needed for react!!
        },

        react: {
            useSuspense: true,
        }
    });

export default i18n;
