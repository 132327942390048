const translationStrings = {
    "siteTitle": "Biuwer - Tu potente plataforma de Analítica de Negocio",
    "underConstruction": "Sitio web en construcción!",
    "contact": "Página de contacto",
    "contactUs": "Contáctanos",

    "mainMenuLinks": {
        "home": "Inicio",
        "product": "Producto",
        "services": "Servicios",
        "pricing": "Precios",
        "blog": "Blog",
        "contact": "Contacto",
        "login": "Inicia sesión",
        "tryForFree": "Prueba gratis",
        "requestDemo": "Solicita una demo",
        "company": "Empresa",
        "about-us": "Sobre nosotros"
    },

    "setupInMinutesSection": {
        "msg1": "Configuración en minutos",
        "msg2": "No es necesario instalar nada o comprar tus propios servidores.",
        "msg3": "Simplemente regístrate y empieza a trabajar, con un pago por uso con precios asequibles.",
        "msg4": "Accede o crea tu cuenta",
        "msg5": "Conecta o sube tus datos",
        "msg6": "Diseña informes y cuadros de mando",
        "msg7": "Analiza e interactúa con los datos"
    },

    "requestDemoSection": {
        "msg1": "¿Quieres saber más?",
        "msg2": "Contáctanos para agendar una demo personalizada y configurar tu suscripción",
        "msg3": "Solicitar una demo",
        "msg4": "Características principales",
        "msg5": "Probar ahora",
    },

    "contactPage": {
        "pageTitle": "Pregúntanos",
        "form": {
            "emailLabel": "Email corporativo",
            "emailPlaceHolder": "Introduce un email válido",
            "emailErrorText": "El campo email no es válido",
            "firstNameLabel": "Nombre",
            "lastNameLabel": "Apellidos",
            "companyLabel": "Empresa",
            "phoneLabel": "Número de teléfono",
            "messageLabel": "Mensaje",
            "messagePlaceHolder": "¿En que te podemos ayudar?",
            "submitLabel": "Enviar",
            "formSubmittedLabel": "Mensaje enviado",
            "formSubmittedMessage": "Gracias por tu interés. Contactaremos contigo en breve."
        }
    },

    "product": {
        "header": {
            "msg1": "Logra un verdadero crecimiento impulsado por los datos",
            "msg2": "Preparado para Big Data",
            "msg3": "Conéctate a las plataformas Big Data más populares",
            "msg4": "Capacidades Machine Learning",
            "msg5": "Utiliza la experiencia de Google Cloud ML para definir y entrenar modelos y así obtener predicciones de tus KPIs",
        },
        "content": {
            "msg1": "El contenido es el rey",
            "msg2": "En Biuwer el contenido se gestiona con",
            "msg3": "Espacios, Páginas y Tarjetas",
            "msg4": "Editor de Tarjetas interactivo",
            "msg5": "Arrastra y Suelta",
            "msg6": "Arrastra y suelta campos de datos, con soporte para múltiples consultas por tarjeta.",
            "msg7": "No necesitas conocimientos técnicos",
            "msg8": "El motor de consultas de Biuwer compone la consulta por tí, dependiendo de le tecnología utilizada, sin necesidad de saber SQL o programación.",
            "msg9": "Aplica tus propios estilos",
            "msg10": "Puedes personalizar el aspecto de gráficos y visualizaciones con tus propios estilos, usando temas y paletas de colores."
        },
        "filters": {
            "msg1": "Sistema de Filtros",
            "msg2": "Biuwer tiene un sistema de filtrado de datos completo, dinámico e interactivo",
            "msg3": "Configura tus Filtros",
            "msg4": "Se pueden definir Filtros en Páginas y Tarjetas, y serán aplicados en las Tarjetas que selecciones sobre cualquiera de sus consultas de datos.",
            "msg5": "Utiliza los filtros dependientes cuando los campos de datos estén relacionados jerárquicamente. Por ejemplo, Países y Provincias, o Comerciales y Clientes.",
            "msg6": "Puedes configurar si un filtro se muestra u oculta, es obligatorio u opcional, es de selección simple o múltiple, cuantos valores mostrar, y mucho más...",
            "msg7": "Utiliza tus Filtros",
            "msg8": "Múltiples opciones de configuración: una selección de una lista de valores, escribir una expresión, usar valores predefinidos, obtener  los valores que más, menos o estén en un intervalo sobre cualquier métrica.",
            "msg9": "La pestaña Predefinidos es quizás la más útil para campos de fecha, ya que muestra los valores más comunes para los Años, Trimestres, Meses y Días.",
            "msg10": "La pestaña Primeros le permite al usuario filtrar, por ejemplo, sobre los 10 clientes que han comprado menos o los países del 5 al 10 que tienen más margen neto.",
        },
        "connectors": {
            "msg1": "Integraciones",
            "msg2": "Conéctate a tus datos de forma segura y flexible",
            "googleBigQuery": "Google BigQuery",
            "amazonRedshift": "Amazon Redshift",
            "snowflake": "Snowflake",
            "azureSql": "Azure SQL",
            "mysql": "MySQL",
            "postgres": "PostgreSQL",
            "mongodb": "MongoDB Server",
            "sqlServer": "SQL Server",
            "oracle": "Oracle DB",
            "sqlite": "SQLite",
            "mariadb": "MariaDB",
            "googleCloudMysql": "Cloud MySQL",
            "googleCloudPostgres": "Cloud PostgreSQL",
            "ftp": "FTP / SFTP",
            "excel": "Ficheros de datos Excel",
            "csv": "Ficheros de datos CSV",
            "json": "Ficheros de datos JSON",
            "googleSpreadsheets": "Google Spreadsheets",
            "googleAnalytics": "Google Analytics",
            "amazonS3": "Amazon S3",
            "clickhouse": "ClickHouse",
            "wooCommerce": "WooCommerce",
            "prestashop": "PrestaShop",
            "hubspot": "Hubspot CRM",
            "apiRestBatch": "API REST Batch",
            "webhooks": "Webhooks",
            "notion": "Notion",
            "slack": "Slack",
            "stripe": "Stripe",
            "pipedrive": "Pipedrive",
            "salesforce": "Salesforce",
            "zoho": "Zoho CRM",
            "businessCentralCloud": "Business Central Cloud",
            "holded": "Holded",
            "xero": "Xero",
            "quickbooks": "QuickBooks",
            "odoo": "Odoo",
            "twilio": "Twilio",
            "aircall": "Aircall",
            "googleAnalyticsV4": "Google Analytics 4",
            "googleAds": "Google Ads",
            "googleMyBusiness": "Google My Business",
            "googleSearchConsole": "Google Search Console",
            "facebookAds": "Facebook Ads",
            "linkedinPages": "Linkedin Pages",
            "linkedinAds": "Linkedin Ads",
            "clockify": "Clockify",
            "shopify": "Shopify",
            "customConnector": "Conector personalizado"
        },
        "comingSoon": "Próximamente",
        "dataCenter": {
            "msg1": "Centro de Datos",
            "msg2": "Gestiona todas tus Conexiones, Juegos de Datos y Modelos de datos en un único lugar",
            "msg3": "Gestión de Datos Híbrida",
            "msg4": "Datos gestionados por tí + Datos gestionados por Biuwer",
            "msg5": "Conexiones",
            "msg6": "Configura y gestiona los parámetros que Biuwer utiliza para conectar con cualquier origen de datos. Biuwer usará autenticación Oauth cuando esté disponible.",
            "msg7": "Juegos de Datos",
            "msg8": "Los datos se representan en Biuwer como un conjunto de Juegos de Datos. Dependiendo de la tecnología pueden ser una tabla o vista de una base de datos, la respuesta de un API, un fichero subido, etc.",
            "msg9": "Modelos de Datos",
            "msg10": "Una de las claves del motor de consultas de Biuwer, donde un conjunto de Juegos de Datos se relacionan para responder cualquier necesidad de datos que tengas."
        },
        "exportShare": {
            "msg1": "Exporta y comparte tus resultados",
            "msg2": "Puedes guardar o compartir fuera de Biuwer los resultados mostrados en Páginas y Tarjetas",
            "msg3": "Actualmente los formatos disponibles son CSV, Excel y PDF.",
            "msg4": "Los documentos exportados son configurables, incluyendo el título del documento, el tamaño y la orientación de impresión, el tamaño de los márgenes, la cabecera y el pie de página donde se pueden incluir: un título personalizado, la fecha de generación del documento, el número de páginas y más..."
        },
        "security": {
            "msg1": "Seguridad y Control de Acceso",
            "msg2": "Biuwer dispone de un sistema de control de acceso basado en",
            "msg3": "Usuarios, Grupos y Roles",
            "msg4": "Seguridad Heredada o Individual",
            "msg5": "Los usuarios pueden configurar la seguridad individualmente por objeto o jerárquicamente usando herencia de permisos.",
            "msg6": "Biuwer tiene 5 roles predefinidos y la configuración de seguridad permite dar acceso de nivel Admin, Editar o Ver.",
            "msg7": "Políticas de Datos",
            "msg8": "Permiten definir el acceso a los datos a nivel de fila en cada juego de datos, para cada Usuario y Grupo.",
            "msg9": "Consigue que la misma Página o Tarjeta muestre datos diferentes a diferentes usuarios sin ninguna modificación."
        }
    },

    "productUpdates": {
        "backLabel": "Atrás",
        "noContentLabel": "No hay contenido",
        "nextLabel": "Siguiente",
        "prevLabel": "Anterior",
        "title": "Actualizaciones de producto"
    },

    "blog": {
        "backLabel": "Atrás",
        "noContentLabel": "No hay contenido",
        "nextLabel": "Siguiente",
        "prevLabel": "Anterior",
        "title": "Blog de Biuwer",
        "postedBy": "Escrito por",
        "category": {
            "product": "Producto",
            "dataVisualization": "Visualización de Datos",
            "dataDrivenGrowth": "Crecimiento Dirigido por Datos",
            "dataUseCase": "Caso de Uso de Datos",
            "businessIntelligence": "Inteligencia de Negocio",
            "biuwer": "Biuwer"
        },
        "readMore": "Leer más"
    },

    "footer": {
        "links": {
            "product": "Producto",
            "services": "Servicios",
            "pricing": "Precios",
            "blog": "Blog",
            "docs": "Documentación",
            "company": "Empresa",
            "contact": "Contacto"
        },
        "builtWith": "Desarrollado con",
        "by": "por",
        "connectWithUs": "Conecta con nosotros",
        "trademarkMessage": "Biuwer Analytics S.L. - Todos los derechos reservados.",
        "termsOfService": "Terminos de Servicio",
        "privacyPolicy": "Política de Privacidad",
        "cookiePolicy": "Política de Cookies",
        "supportedBy": "Con el apoyo de",
        "supportedByMessageXpande": "Biuwer Analytics, S.L. ha sido beneficiaria del Fondo Europeo de Desarrollo Regional cuyo objetivo es mejorar la competitividad de las Pymes y gracias al cual ha puesto en marcha un Plan de Marketing Digital Internacional con el objetivo de mejorar su posicionamiento online en mercados exteriores durante el año 2023. Para ello ha contado con el apoyo del Programa XPANDE DIGITAL de la Cámara de Comercio de Murcia. Una manera de hacer Europa."
    },
    "cookiesControl":{
        "buttonTextAccept":"Aceptar todas",
        "buttonTextDecline":"Rechazar",
        "messageText":`Utilizamos cookies para mejorar la experiencia del usuario, proporcionar funciones de redes sociales, personalizar contenido y anuncios y analizar el tráfico del sitio web. Por estos motivos, podemos compartir los datos de uso de su sitio con nuestros socios de análisis. Al hacer clic en "Aceptar todas", aceptas almacenar en tu dispositivo todas las tecnologías descritas en nuestra`,
        "linkText":"Política de cookies",
    },
    "page404":{
        "pageNotFound":"¡Lo siento! No podemos encontrar esta página.",
        "message1":"O bien ha sido retirada o estamos ocupados arreglándolo.",
        "message2":"Ve a un lugar más útil en su lugar.",
        "message3":"Haz clic en uno de estos enlaces y echa un vistazo:",
        "link1":"Inicio",
        "link2":"Atrás"

    }
};

export default translationStrings;