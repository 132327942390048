const homeStrings = {
    "headerSection": {
        "msg1": "La plataforma de Embedded Analytics para empresas de software y SaaS",
        "msg2": "Muestra insights de alto valor a tus usuarios mediante visualizaciones de datos integradas en tu aplicación.",
        "msg3": "100% basado en la nube, ¡siempre actualizado!",
        "msg4": "Fácil de usar, ¡sin conocimientos técnicos!",
        "msg5": "Prueba gratuita de 30 días. No se necesita tarjeta de crédito"
    },
    "meetBiuwerSection": {
        "title": "Conozca una de las plataformas de análisis de datos más flexibles del mundo",
        "description": "Biuwer ayuda a las personas a ver y comprender los datos para tomar acciones proactivas que conllevan múltiples beneficios: mejorar los procesos, ahorrar tiempo, tener más ventas.",
        "trustMsg": "Organizaciones de todos los tamaños confían en Biuwer para ser guiadas por los datos."
    },
    "whyBiuwerSection": {
        "title": "¿Por qué Biuwer?",
        "description": "Un conjunto de funcionalidades hacen que Biuwer sea único. Nos gusta decir que es una caja de herramientas, por su flexibilidad y la capacidad de construir una solución de datos por diferentes caminos.",
        "productDetails": "Más detalles del producto",
        "easeOfUse": "Facilidad de uso",
        "easeOfUseMsg": "Analizar datos debería consistir en <1>hacer cualquier tipo de pregunta a nuestros datos</1>, no en aprender a utilizar software. Conociendo tus datos, <3>puedes realizar exploraciones y análisis colaborativos ilimitados</3> con Biuwer. Por eso a la gente le encanta usarlo.",
        "dataUsedEveryone": "Todos deberían usar datos",
        "dataUsedEveryoneMsg": "Uno de los principales objetivos de Biuwer es democratizar el análisis de datos. Por eso <1>todos nuestros planes de pago incluyen ilimitados usuarios básicos</1>, para que cualquiera pueda analizar los datos que necesita, 24x7 desde cualquier lugar.",
        "onePlatform": "Una plataforma, muchos casos de uso",
        "onePlatformMsg": "El <1>Centro de Datos de Biuwer unifica y simplifica la gestión de datos</1>. Accede de forma segura a cualquier contenido que use datos mediante varios casos de uso: <3>análisis interno, análisis integrado, análisis de API, solución de datos todo en uno</3>, etc.",
        "saveTimeMoney": "Ahorra tiempo, gana más dinero",
        "saveTimeMoneyMsg": "Creemos firmemente en el <1>crecimiento impulsado por datos</1>. El uso diario de Biuwer te ayuda a tomar decisiones inteligentes basadas en datos. <3>¡Automatiza tus datos y consigue un ROI muy alto!.</3>",
        "cloudBased": "Basado en Cloud",
        "cloudBasedMsg": "Utiliza Biuwer desde cualquier dispositivo y desde cualquier navegador web, las 24 horas del día. No es necesario instalar nada, actualizado automáticamente con las últimas funcionalidades.",
        "noCodingRequired": "No son necesarios conocimientos técnicos",
        "noCodingRequiredMsg": "Biuwer es fácil de usar, no tienes que ser un analista de datos o tener conocimientos técnicos de SQL para usarlo.",
        "customizable": "Personalizable",
        "customizableMsg": "Los ingenieros de datos de Biuwer y nuestra red de partners pueden implementar Tarjetas Personalizadas tales como integraciones con aplicaciones, gráficos a medida, informes a medida, métricas KPI personalizadas, mapas a medida, formularios personalizados… cualquier cosa que puedas necesitar.",
        "affordablePricing": "Precios asequibles",
        "affordablePricingMsg": "Evita pagar miles de euros en caras licencias desde el día uno. Tenemos el plan adecuado a tus necesidades, con hasta miles de usuarios y petabytes de datos."
    },
};

export default homeStrings;