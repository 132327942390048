import React from 'react';
import { grey } from "material-colors";

// Custom imports
import MainMenu from './menu-data';

const MainMenuDesktop = (props) => {
    const { i18n, collapseMenu } = props;

    const styles = {
        navbarWrapper: {
            position: 'relative',
            marginBottom: 'inherit',

            ":after": {
                content: '" "',
                display: 'block',
                height: '20px',
                width: '2px',
                background: grey[400],
                position: 'absolute',
                top: '6px',
                right: 0,
                pointerEvents: 'none'
            }
        }
    };

    return (
        <ul
            className="navbar-nav ml-auto"
            css={styles.navbarWrapper}
        >
            <MainMenu 
                isMobileMenuOpen={false}
                i18n={i18n}
                collapseMenu={collapseMenu}
            />
        </ul>
    );
};

export default MainMenuDesktop;
