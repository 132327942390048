const lpStrings = {
    "freeQuickStartPackage": {
        "title": "Biuwer Free Quick Start Package",
        "subTitle": "Take advantage of this offer and grow your company!",
        "question1": "Do you know how your company is performing?",
        "question2": "Which Regions and Countries to expand to?",
        "question3": "Which expenditure items to reduce costs?",
        "question4": "What your best sales people are?",
        "getTheMostOfYourData": "Get the most out of your company's data and leave the competition behind",
        "benefit1": "Save time and reduce costs",
        "benefit2": "Increase sales and improve your profitability",
        "benefit3": "Improve the communication between company members",
        "imInterested": "I am interested",
        "whatsIncluded": "The Free Starter Pack includes:",
        "included1": "Analysis of the needs of the company.",
        "included2": "Connection with data sources and definition of data models (analysis dimensions, KPIs, necessary calculations, etc.).",
        "included3": "Assembly of the first reports and dashboards in Biuwer, including interactive analysis filters.",
        "included4": "Access security settings, registering key users.",
        "valuedAt": "Valued at up to $2,800. For free!",
        "plansToPurchase": "Purchasing an Annual Professional plan or an Annual Standard plan",
        "onlyUntil": "Only until 30 June 2022",
        "onlyFor": "Only for the first 20 companies",
        "50HoursIncluded": "Up to 50 hours with an Annual Professional plan",
        "20HoursIncluded": "Up to 20 hours with an Annual Standard plan",
        "startNow": "START NOW!",
        "completeForm": "Complete this form and we'll get in touch shortly"
    },
    "aceleraPyme": {
        "title": "Biuwer, partner of the Acelera Pyme program",
        "subTitle": "The initiative helps Spanish SMEs and freelancers to mitigate the impact of COVID-19 on their activity",
        "question": "What is Biuwer?",
        "biuwer_desc1": "Biuwer is your cloud platform for Data Analysis",
        "biuwer_desc2": "Make smart decisions with your data, increase your income and reduce your expenses",
        "biuwer_desc3": "Cloud-based, no technical knowledge required, customizable, scalable, and with high ROI"

    }
};

export default lpStrings;