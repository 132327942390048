import React from 'react';
import CookieConsent from "react-cookie-consent";
//import {Link} from "gatsby";
import { useTranslation } from 'react-i18next';
import { green } from 'material-colors';
const style = {
    background: "#2B373B",
    width: "80%",
    margin: "24px",
};


const CookieConsentComponent = () => {
    const { t, i18n } = useTranslation();
    return(
        <CookieConsent
            expires={365}
            location="bottom"
            buttonText={t("cookiesControl.buttonTextAccept")}
            //declineButtonText = {t("cookiesControl.buttonTextDecline")}
            cookieName="bw.cookieConsent"
            style={style}
            buttonStyle={{ backgroundColor: green[500], color: "#fff" , fontSize: "13px" }}
            //enableDeclineButton
            flipButtons
        >
            <p>{t("cookiesControl.messageText")} <a target="_blank"  rel="noopener noreferrer" href={ i18n && i18n.language === "es" ? "http://www.datuary.com/es/aviso-legal" : "http://www.datuary.com/legal-advice"}>{t("cookiesControl.linkText")}</a>
</p>
        </CookieConsent>
    );
};

export default CookieConsentComponent;
