const pricingStrings = {
    "introduction": "Un plan de precios para cada necesidad",
    "free_trial": "Prueba gratuita de 30 días",
    "signup": "Regístrate",
    "trial": {
        "start": "Empieza tu prueba de 30 días gratis ahora",
        "no_setup": "Sin coste de instalación",
        "no_contracts": "Sin contratos",
        "cancel_anytime": "Cancela en cualquier momento",
        "no_credit_card": "No se requiere tarjeta de crédito",
        "prices_without_taxes": "Los precios mostrados en esta página no incluyen impuestos.",
        "vat_info": "Dependiendo del país, puede aplicarse el IVA u otros impuestos locales sobre las ventas al finalizar el proceso de compra.",
        "try_for_free": "¡Prueba gratis!",
        "create_account": "Crear una cuenta"
    },
    "most_popular": "¡El más popular!",
    "per_month": "por mes",
    "billed_yearly": "facturado anualmente",
    "contact_us": "Contáctanos",
    "unlimited": "Ilimitado",
    "choose_billing_period": "Periodo de facturación",
    "monthly": "Mensual",
    "annual": "Anual",
    "annual_reward": "¡2 meses gratis!",
    "choose_currency": "Moneda",
    "plans": {
        "basic": {
            "name": "Básico",
            "best_for": "Comienza a analizar y tomar decisiones con datos internamente, sin incrustación de datos externa.",
            "feature_intro": "Características destacadas:"
        },
        "standard": {
            "name": "Estándar",
            "best_for": "Entrega insights accionables a tus usuarios a cualquier escala, de forma rápida, visual e intuitiva.",
            "feature_intro": "Características Básicas más:"
        },
        "professional": {
            "name": "Profesional",
            "best_for": "Automatiza y personaliza experiencias de analítica embebida premium con total flexibilidad.",
            "feature_intro": "Características Estándar más:"
        },
        "enterprise": {
            "name": "Empresarial",
            "best_for": "Plataforma completa y personalizada de analítica embebida. Para corporaciones y agencias.",
            "designed_for": "El plan Empresarial está diseñado para empresas con necesidades específicas de puesta en marcha, un soporte continuado y un mantenimiento personalizado.",
            "feature_intro": "Características Profesionales más:"
        }
    },
    "included": {
        "intro_plan": "El plan incluye (*):",
        "title": "Incluido en todos los planes",
        "cards": "Todo tipo de Tarjetas",
        "cardsMsg": "KPIs (indicadores), gráficos (+14 tipos), tablas vertical, tablas (dinámicas) cruzadas, mapas, tarjetas personalizadas.",
        "content": "Contenido ilimitado",
        "contentMsg": "No hay límite en el número o complejidad de Espacios Compartidos, Colecciones, Páginas o Tarjetas creadas en tu Organización.",
        "dataConnections": "Conexiones de Datos ilimitadas",
        "dataConnectionsMsg": "Utiliza cualquiera de las conexiones de datos disponibles, incluidas las bases de datos, APIs y archivos más utilizados.",
        "dataObjects": "Objetos de Datos ilimitados",
        "dataObjectsMsg": "Los Modelos de Datos y los Conjuntos de Datos definen tus datos y sus relaciones. Utiliza tantos Conjuntos de Datos como necesites y modélalos con cualquier número de Contextos de Datos.",
        "dataUsage": "Uso de datos ilimitado (gratis temporalmente)",
        "temporarilyFree": "Por tiempo limitado, tienes uso ilimitado de datos de forma gratuita.",
        "dataUsageMsg": "Biuwer sigue un mecanismo de precios basado en el uso. Esto significa que solo se te cobrará por los datos utilizados cada mes. Por tiempo limitado, tienes uso ilimitado de datos de forma gratuita.",
    },
    "promo_codes": {
        "quick_start_package": "¡Paquete de Inicio Rápido gratis!"
    },
    "features": {
        // Included in BASIC plan
        "basic_connectors": "Conectores Básicos",
        "upload_data_file": "Sube datos desde CSV/Excel",
        "export_data_file": "Exporta a CSV, Excel, PDF",
        "favorites": "Contenido Favorito",
        "page_card_filters": "Filtros de Página y Tarjeta",
        "dependent_filters": "Filtros Dependientes",
        // Included in STANDARD plan
        "embedding": "Incrustación de datos externa",
        "row_access_level": "Acceso a nivel de fila",
        "data_blending": "Combinación de datos",
        "rbac": "Seguridad con Grupos y Roles",
        "reverse_engineering": "Ingeniería Inversa",
        "calculated_fields": "Campos Calculados",
        // Included in PROFESSIONAL plan
        "professional_connectors": "Conectores Profesionales",
        "api_access": "Acceso al API Pública",
        "custom_styles": "Estilos personalizados",
        "multi_language": "Datos y contenido multi idioma",
        "data_portals": "Portales de datos",
        "smart_data_alerts": "Alertas de datos inteligentes",
        // Included in ENTERPRISE plan
        "custom_connectors": "Conectores personalizados",
        "custom_onboarding": "Puesta en marcha a medida",
        "white_labeling": "Marca blanca",
        "dedicated_server": "Servidor dedicado",
        "priority_support": "Soporte prioritario",
        "sso": "Single Sign-On (SSO)"
    },
    "products": {
        "advanced_user": "Usuarios Avanzados",
        "basic_user": "Usuarios Básicos",
        "data_connection": "Conexiones de Datos",
        "data_usage": "Uso de Datos"
    },
    "add_ons": "(*) Número de usuarios, conectores y volúmen de datos adicionales disponibles como add-ons.",
    "faq_title": "Preguntas más frecuentes",
    "faq_intro": "Estas son las preguntas más comunes sobre precios y las principales funcionalidades de Biuwer.",
    "faq_intro_2": "No dudes en comunicarte con nosotros con cualquier otra pregunta que puedas tener.",
    "faqs": {
        "i_need_more": {
            "question": "Necesito más usuarios, más conexiones, más uso de datos. ¿Puedo tener más?",
            "answer": "¡Por supuesto! Los planes incluyen un número de usuarios, conexiones y uso de datos concreto, como punto de partida. Hay una serie de add-ons disponibles, con los que puedes modificar en cualquier momento el número de usuarios, conectores, volúmen de datos en uso, etc."
        },
        "i_want_a_demo": {
            "question": "No conozco Biuwer, ¿puedo ver una demostración antes de tomar una decisión?",
            "answer": "¡Claro! De hecho es el caso más habitual. La experiencia nos dice que nuestros clientes tienen más éxito cuando les ofrecemos una demostración del producto primero, pues hay muchas funcionalidades y posibilidades. <1>Contáctanos</1> y agendamos una demostración online en la que podamos conocerte y dar respuesta a tus preguntas."
        },
        "unlimited_meaning": {
            "question": "¿Qué quiere decir Ilimitado?",
            "answer": "Ilimitado significa que no hay límites predefinidos para la cantidad de <1>Usuarios Básicos, Conexiones de Datos, elementos de Contenido, Objetos de Datos o el Uso de Datos</1> que tu Organización consuma mensualmente. Por supuesto, existen límites reales, pero son bastante altos, por tanto, solo limitaremos aquellas Organizaciones cuyo uso esté muy por encima del promedio en el ecosistema de Biuwer."
        },
        "signup_howto": {
            "question": "¿Cómo funciona el registro?",
            "answer": "Simplemente sigue los pasos que te marcamos para configurar tu organización en Biuwer. Si prefieres que nos pongamos en contacto contigo, <1>contáctanos</1> y te ayudamos en el proceso.",
            "answer_2": "Los nuevos registros comienzan con un <1>periodo de prueba</1>. No es necesario al registrarte que indiques qué plan quieres usar, ni configurar un medio de pago como una tarjeta de crédito o débito."
        },
        "trial_howto": {
            "question": "¿Cómo funciona el periodo de prueba?",
            "answer": "Al registrarte tienes por defecto 30 días para probar Biuwer y comenzarás con todas las funcionalidades disponibles. Aprovecha este periodo para ponerte en contacto con nuestro equipo que te ayudará a configurar y sacar partido a la plataforma."
        },
        "extend_trial": {
            "question": "¿Puedo extender el periodo de prueba?",
            "answer": "Sí, en determinados casos extendemos el periodo de prueba, según tu uso y requerimientos. Ponte en contacto con nosotros desde el soporte online para configurar un periodo de prueba personalizado según tus necesidades."
        },
        "payment_howto": {
            "question": "¿Cuándo se me cobra el plan elegido?",
            "answer": "Como parte de la confirmación para el uso regular de Biuwer, tendrás que seleccionar un plan inicial para tu organización, que entrará en vigor tras el periodo de prueba.",
            "answer_2": "Se te cobrará al iniciar tu ciclo de facturación, que puede ser cada mes o cada año, siempre el mismo día. Por ejemplo, si tu suscripción comienza el 12 de Abril, se te cobrarán los días 12 de cada mes, o bien el día 12 de Abril de cada año, mientras tu suscripción esté activa."
        },
        "annual_advantages": {
            "question": "¿Qué ventajas tiene contratar un plan anual?",
            "answer": "Si eliges cualquier plan con pago anual por anticipado te beneficias de 2 meses gratis aplicados al instante en tu factura. Además, si contratas el <1>Plan Profesional</1>, te regalamos un <3>Paquete de Inicio Rápido</3> para configurar tu organización en Biuwer con nuestros ingenieros de datos."
        },
        "payment_methods": {
            "question": "¿Qué métodos de pago se aceptan?",
            "answer": "Puedes pagar con tu tarjeta de crédito o débito (Visa y Master Card), domiciliación bancaria a través de un adeudo directo SEPA (sólo para Europa) y transferencia bancaria. Podrás elegir entre estos medios de pago al confirmar tu compra, según tu país y el ciclo de facturación elegido, anual o mensual."
        },
        "get_invoices": {
            "question": "¿Cómo puedo obtener facturas del servicio contratado?",
            "answer": "Biuwer está diseñado como un servicio para empresas y siempre generamos una factura con tus datos cada vez que te cobramos. Tienes acceso a tus facturas desde el área de administración de Biuwer y también puedes configurar que se envíen por correo electrónico a las direcciones que indiques."
        },
        "secure_payments": {
            "question": "¿Son seguros los pagos?",
            "answer": "Biuwer utiliza como proveedor de pagos a <1>Stripe</1>, la plataforma de referencia mundial en procesamiento de pagos online y una de las pasarelas de pago globales más seguras y fiables."
        },
        "change_plan": {
            "question": "¿Puedo cambiar de plan o mi periodo de facturación más adelante?",
            "answer": "Sí, puedes cambiar de plan en cualquier momento y los cambios serán efectivos al instante. Se te cobrará (si decides un plan superior) o se incrementará tu balance (si decides un plan inferior) prorrateado por el tiempo restante hasta la siguiente fecha de renovación, en la que se te cobrará el precio normal."
        },
        "cancel_subscription": {
            "question": "¿Puedo cancelar mi suscripción en cualquier momento?",
            "answer": "Sí, puedes cancelar su suscripción en cualquier momento. No hay absolutamente ninguna obligación. Si decides cancelar tu suscripción, perderás el acceso al final del período de facturación actual."
        },
        "professional_services": {
            "question": "Lo quiero todo configurado y funcionando, ¿ofrecéis servicios profesionales?",
            "answer": "Sí, el equipo de ingenieros de datos de Biuwer y nuestra red de partners pueden trabajar contigo para poner todo en funcionamiento. Estos servicios profesionales incluyen cualquier trabajo necesario para conectar y preparar tus datos, automatizar su tratamiento y almacenamiento para acelerar el análisis de datos. Si estás interesado en estos servicios, <1>contáctanos</1> y te prepararemos un presupuesto."
        },
        "need_help": {
            "question": "¿Qué hago si necesito ayuda?",
            "answer": "Si necesitas ayuda, ponemos a tu disposición varias formas de contacto para que el equipo de Biuwer pueda ayudarte.",
            "answer_2": "<0>Ayuda interactiva, a través de nuestro helpdesk</0><1>Documentación, a través de las guías de producto</1><2>Blog, con actualizaciones de producto, casos de uso, etc.</2>"
        },
        "user_types": {
            "question": "¿Qué diferencias hay entre los usuarios avanzados y básicos?",
            "answer": "Los usuarios avanzados pueden utilizar todas las funcionalidades de Biuwer, según cómo se configuren dentro del sistema de seguridad y control de acceso. Además disponen de un espacio de trabajo personal para trabajar con datos individualmente.",
            "answer_2": "Los usuarios básicos únicamente pueden utilizar Biuwer en modo consulta y no tienen espacio personal. Es el tipo de usuario adecuado para una distribución masiva para analizar datos y tomar decisiones, sin necesidad de administrar, crear o editar contenido."
        },
        "connection_types": {
            "question": "¿Qué bases de datos y fuentes de datos puedo utilizar con Biuwer?",
            "answer": "Biuwer es compatible con la mayoría de las bases de datos SQL como MySQL, PostgreSQL, SQL Server, Oracle, BigQuery o Redshift. También admitimos otras fuentes de datos como CSV, Excel y MongoDB. Puedes leer la <1>lista completa aquí</1>.",
            "answer_2": "En caso de que necesites alguna otra tecnología de conexión de datos que no esté actualmente en la lista, por favor contáctanos y estaremos encantados de trabajar en la integración."
        },
        "on_premise": {
            "question": "¿Ofrecéis instalaciones on-premise?",
            "answer": "Como producto cloud no tenemos una versión local de Biuwer, pero en el plan Empresarial ofrecemos la posibilidad de ejecutar una instancia privada de Biuwer en un servidor dedicado. Envíanos un correo electrónico a <1>sales@biuwer.com</1>."
        }
    }
};

export default pricingStrings;