import { Link  } from "gatsby";
import { Icon } from "@blueprintjs/core";
import { grey, amber } from "material-colors";
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import biuwerLogoAmberWhite from '../../../static/img/biuwer-logo-amber-white.svg';
import SupportedBy from "./supported-by";

const Footer =  (props) => {
    const { getUrlToRedirect } = props,
        { t, i18n } = useTranslation(),
        language = i18n.language;

    return (
        <footer css={styles.wrapper}>
            <div className="container" >
                <div
                    className="row"
                    css={styles.content}
                >
                    <div className="col-sm-6 col-md-5 mb15">
                        <div>
                            <img
                                alt="Biuwer"
                                src={biuwerLogoAmberWhite}
                                width='150px'
                            />
                            <hr className="mr20" css={styles.divider} />
                            <ul style={{ listStyle: 'none' }}>
                                <li key="enSwitcher">
                                    <Link
                                        key="enSwitcher"
                                        to={getUrlToRedirect && getUrlToRedirect('en')}
                                        css={styles.linkLanguageSwitcher}
                                        onClick={ () => i18n.changeLanguage('en')}
                                    >
                                        English
                                    </Link>
                                </li>
                                <li key="esSwitcher">
                                    <Link
                                        key="esSwitcher"
                                        to={getUrlToRedirect && getUrlToRedirect('es')}
                                        css={styles.linkLanguageSwitcher}
                                        onClick={ () => i18n.changeLanguage('es')}
                                    >
                                        Español
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="col-sm-6 col-md-3 mb15">
                        <h4 css={styles.title}>Biuwer</h4>
                        <nav>
                            <ul css={styles.ul}>
                                <li css={styles.menuItem}>
                                    <Link
                                        css={styles.menuItemLink}
                                        to={ language === 'es' ? '/es/producto/' : '/en/product/' }
                                    >
                                        {t('footer.links.product')}
                                    </Link>
                                </li>
                                <li css={styles.menuItem}>
                                    <Link
                                        css={styles.menuItemLink}
                                        to={ language === 'es' ? '/es/servicios/' : '/en/services/' }
                                    >
                                        {t('footer.links.services')}
                                    </Link>
                                </li>
                                <li css={styles.menuItem}>
                                    <Link
                                        css={styles.menuItemLink}
                                        to={ language === 'es' ? '/es/precios/' : '/en/pricing/' }
                                    >
                                        {t('footer.links.pricing')}
                                    </Link>
                                </li>
                                <li css={styles.menuItem}>
                                    <Link
                                        css={styles.menuItemLink}
                                        to={ language === 'es' ? '/es/blog/' : '/en/blog/' }
                                    >
                                        {t('footer.links.blog')}
                                    </Link>
                                </li>
                                <li css={styles.menuItem}>
                                    <Link
                                        css={styles.menuItemLink}
                                        to={ language === 'es' ? '/es/actualizaciones-producto/' : '/en/product-updates/' }
                                    >
                                        {t('productUpdates.title')}
                                    </Link>
                                </li>
                                <li css={styles.menuItem}>
                                    <a
                                        css={styles.menuItemLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={language === 'es' ? 'https://docs.biuwer.com/v/es/' : 'https://docs.biuwer.com/'}
                                    >
                                        {t('footer.links.docs')}
                                    </a>
                                    <Icon icon="share" iconSize={12} color={grey[500]} className="ml10 mt5" />
                                </li>
                                <li style={styles.menuItem}>
                                    <Link
                                        css={styles.menuItemLink}
                                        to={language === 'es' ? '/es/sobre-nosotros/' : '/en/about-us/'}
                                    >
                                        {t('footer.links.company')}
                                    </Link>
                                </li>
                                <li css={styles.menuItem}>
                                    <Link
                                        css={styles.menuItemLink}
                                        to={ language === 'es' ? '/es/contacto/' : '/en/contact/' }
                                    >
                                        {t('footer.links.contact')}
                                    </Link>
                                </li>
                                <li css={styles.menuItem}>
                                    <Link
                                        css={styles.menuItemLink}
                                        to={ language === 'es' ? '/es/lp/kit-digital/' : '/es/lp/kit-digital/' }
                                    >
                                        Kit Digital Business Intelligence y Analítica
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="col-sm-12 col-md-4">
                        <h4 css={styles.title}>{t('footer.links.contact')}</h4>
                        <ul css={styles.ul}>
                            <li className="mb5">
                                <span>CEEIM, Campus Universitario Espinardo, Murcia, SPAIN </span>
                            </li>
                            <li className="mb5">
                                <span>(+34) 868 941 353</span>
                            </li>
                            <li className="mb5">
                                <a href="mailto:sales@biuwer.com"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   style={{color: amber[500]}}
                                >
                                    sales@biuwer.com
                                </a>
                            </li>
                            <li>
                                <span className="mr15">{t('footer.connectWithUs')}:</span>
                                <a
                                    href="https://twitter.com/biuwer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: grey[300] }}
                                >
                                    <i className="fab fa-twitter-square mr10" />
                                </a>

                                <a
                                    href="https://www.linkedin.com/company/biuwer/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: grey[300] }}
                                >
                                    <i className="fab fa-linkedin mr10" />
                                </a>
                                <a
                                    href="https://www.facebook.com/BiuwerApp/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: grey[300] }}
                                >
                                    <i className="fab fa-facebook mr10" />
                                </a>
                                <a
                                    href="https://www.instagram.com/biuwer/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: grey[300] }}
                                >
                                    <i className="fab fa-instagram mr10" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr css={styles.divider}/>
                <SupportedBy 
                    t={t}
                    i18n={i18n} 
                />
                <div
                    className="row"
                    css={styles.postFooter}
                >
                    <div className="col-sm-6 col-12">
                        <p>
                            {moment().year()} © {t('footer.trademarkMessage')}
                        </p>
                    </div>
                    <div className="col-sm-6 col-12 text-end">
                        <p>
                            <Link
                                to={ language === 'es' ? '/es/legal/terminos-de-servicio/' : '/en/legal/terms-of-service/' }
                                css={styles.menuItemLink}
                                className="mr15"
                            >
                                {t('footer.termsOfService')}
                            </Link>
                            <Link
                                to={ language === 'es' ? '/es/legal/politica-de-privacidad/' : '/en/legal/privacy-policy/' }
                                css={styles.menuItemLink}
                                className="mr15"
                            >
                                {t('footer.privacyPolicy')}
                            </Link>
                            <Link
                                to={ language === 'es' ? '/es/legal/politica-de-cookies/' : '/en/legal/cookie-policy/' }
                                css={styles.menuItemLink}
                                className="mr15"
                            >
                                {t('footer.cookiePolicy')}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

const styles = {
    wrapper: {
        backgroundColor: '#012e67',
        color: grey[300]
    },
    content: {
        paddingTop: '40px'
    },
    divider: {
        backgroundColor: 'rgba(255, 255, 255, .5)'
    },

    title: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        fontSize: '17px',
        color: grey[500],
        letterSpacing: '1.75px',
        textTransform: 'uppercase'
    },

    ul: {
        listStyle: 'none',
        padding: 0
    },
    menuItem: {
        display: 'flex',
    },
    menuItemLink: {
        color: grey[100],
        paddingBottom: '8px',
        cursor: 'pointer',

        '&:hover': {
            color: amber[500]
        }
    },
    postFooter: {
        padding: '10px 0',
        fontSize: '12px',
        lineHeight: '18px',
    },

    linkLanguageSwitcher: {
        marginRight: '30px',
        fontSize: '15px',
        color: grey[100],
        textDecoration: 'none',
        cursor: 'pointer',
        letterSpacing: '1px',

        '&:hover': {
            color: amber[500]
        }
    }
};

export default Footer;