const translationStrings = {
    "siteTitle": "Biuwer - Your powerful Business Analytics platform",
    "underConstruction": "Site under construction!",
    "contact": "Contact page",
    "contactUs": "Contact us",

    "mainMenuLinks": {
        "home": "Home",
        "product": "Product",
        "services": "Services",
        "pricing": "Pricing",
        "blog": "Blog",
        "contact": "Contact",
        "login": "Log in",
        "tryForFree": "Try for free",
        "requestDemo": "Request a demo",
        "company": "Company",
        "about-us": "About us"
    },

    "setupInMinutesSection": {
        "msg1": "Set up in minutes",
        "msg2": "No need to install anything or buy your own servers.",
        "msg3": "Simply signup and start working, with an affordable pay as you go pricing.",
        "msg4": "Access or create your account",
        "msg5": "Connect or upload your data",
        "msg6": "Design reports and dashboards",
        "msg7": "Analyze and interact with data"
    },

    "requestDemoSection": {
        "msg1": "Do you want to know more?",
        "msg2": "Reach us to schedule a personalized demo and configure your subscription",
        "msg3": "Request a demo",
        "msg4": "Product main features",
        "msg5": "Try for free",
    },

    "contactPage": {
        "pageTitle": "Ask us a question",
        "form": {
            "emailLabel": "Work email",
            "emailPlaceHolder": "Enter a valid email",
            "emailErrorText": "The field email is wrong",
            "firstNameLabel": "First Name",
            "lastNameLabel": "Last Name",
            "companyLabel": "Company",
            "phoneLabel": "Phone Number",
            "messageLabel": "Message",
            "messagePlaceHolder": "What questions do you have for us?",
            "submitLabel": "Send",
            "formSubmittedLabel": "Message sent",
            "formSubmittedMessage": "Thanks for your interest. We will contact you shortly."
        }
    },

    "product": {
        "header": {
            "msg1": "Achieve a true data-driven growth",
            "msg2": "Big Data ready",
            "msg3": "Connect to the most popular Big Data platforms",
            "msg4": "Machine Learning capabilities",
            "msg5": "Use Google Cloud ML experience to define and train models and then obtain predictions over your KPIs",
        },
        "content": {
            "msg1": "Content is the king",
            "msg2": "Content in Biuwer is managed using",
            "msg3": "Spaces, Pages and Cards",
            "msg4": "Benefits of the interactive Card editor",
            "msg5": "Drag & Drop",
            "msg6": "Drag & Drop data fields, with multiple queries support for each Card.",
            "msg7": "No technical knowledge needed",
            "msg8": "Biuwer query engine composes the query for you, depending on the data technology used, no need to know SQL or programming.",
            "msg9": "Apply your own styles",
            "msg10": "You can customize charts and visualizations look & feel with your own styles, using style themes and color palettes."
        },
        "filters": {
            "msg1": "Filter System",
            "msg2": "Biuwer has a complete, dynamic and interactive data filtering system",
            "msg3": "Configure your Filters",
            "msg4": "Filters can be defined in Pages and Cards and will be applied on a selection of Cards of your choice to any of their data queries.",
            "msg5": "Use dependent filters when data fields are related hierarchycally. For example, Countries and Provinces, or Sales Agents and Customers.",
            "msg6": "You can configure if a filter is shown or hidden, mandatory or optional, simple or multiple selection, the number of values to show, and much more...",
            "msg7": "Use your Filters",
            "msg8": "Multiple configuration options: selection from a list of values, write an expression, use predefined values, obtain the top, bottom or any interval of values based on any metric field.",
            "msg9": "The Predefined tab is maybe the most useful for date fields, as it shows the most common values for Years, Quarters, Months and Days.",
            "msg10": "The Top tab allows the user to filter, for example, over the 10 customers that have purchased the most or the countries 5 to 10 that have more net margin.",
        },
        "connectors": {
            "msg1": "Integrations",
            "msg2": "Connect to your data in a secure and flexible way",
            "googleBigQuery": "Google BigQuery",
            "amazonRedshift": "Amazon Redshift",
            "snowflake": "Snowflake",
            "azureSql": "Azure SQL",
            "mysql": "MySQL",
            "postgres": "PostgreSQL",
            "mongodb": "MongoDB Server",
            "sqlServer": "SQL Server",
            "oracle": "Oracle DB",
            "sqlite": "SQLite",
            "mariadb": "MariaDB",
            "googleCloudMysql": "Cloud MySQL",
            "googleCloudPostgres": "Cloud PostgreSQL",
            "ftp": "FTP / SFTP",
            "excel": "Excel data files",
            "csv": "CSV data files",
            "json": "JSON data files",
            "googleSpreadsheets": "Google Spreadsheets",
            "googleAnalytics": "Google Analytics",
            "amazonS3": "Amazon S3",
            "clickhouse": "ClickHouse",
            "wooCommerce": "WooCommerce",
            "prestashop": "PrestaShop",
            "hubspot": "Hubspot CRM",
            "apiRestBatch": "API REST Batch",
            "webhooks": "Webhooks",
            "notion": "Notion",
            "slack": "Slack",
            "stripe": "Stripe",
            "pipedrive": "Pipedrive",
            "salesforce": "Salesforce",
            "zoho": "Zoho CRM",
            "businessCentralCloud": "Business Central Cloud",
            "holded": "Holded",
            "xero": "Xero",
            "quickbooks": "QuickBooks",
            "odoo": "Odoo",
            "twilio": "Twilio",
            "aircall": "Aircall",
            "googleAnalyticsV4": "Google Analytics 4",
            "googleAds": "Google Ads",
            "googleMyBusiness": "Google My Business",
            "googleSearchConsole": "Google Search Console",
            "facebookAds": "Facebook Ads",
            "linkedinPages": "Linkedin Pages",
            "linkedinAds": "Linkedin Ads",
            "clockify": "Clockify",
            "shopify": "Shopify",
            "customConnector": "Your custom connector"
        },
        "comingSoon": "Coming soon",
        "dataCenter": {
            "msg1": "Data Center",
            "msg2": "Manage all Connections, Datasets and Data Models in one place",
            "msg3": "Hybrid Data Management",
            "msg4": "Data managed by you + Data managed by Biuwer",
            "msg5": "Connections",
            "msg6": "Configure and manage the parameters that Biuwer uses to connect to any data source. Biuwer will use Oauth authentication when available.",
            "msg7": "Datasets",
            "msg8": "Data is represented in Biuwer as a collection of Datasets. Depending on the integration technology it can be a database table or view, an API response, a file upload, etc.",
            "msg9": "Data Models",
            "msg10": "One of the keys of Biuwer query engine, where a collection of Datasets are related between them, to answer any complex data need."
        },
        "exportShare": {
            "msg1": "Export and Share results",
            "msg2": "You can save and share out of Biuwer the results shown in Pages and Cards",
            "msg3": "Currently the available formats are CSV, Excel and PDF.",
            "msg4": "The exported documents are configurable, including the document title, the print size and orientation, size of margins, header and footer where you can include: a custom title, document date, number of pages and more..."
        },
        "security": {
            "msg1": "Security and Access Control",
            "msg2": "Biuwer has a builtin access control system based on",
            "msg3": "Users, Groups and Roles",
            "msg4": "Inherited or Individual security",
            "msg5": "Users can configure security individually per object or hierarchically using permission inheritance.",
            "msg6": "Biuwer has 5 predefined roles and the security configuration allows you to give Admin, Edit or View access level.",
            "msg7": "Data Policies",
            "msg8": "They allow you to define data access per row level in each dataset, for each User and Group.",
            "msg9": "The immediate effect is that the same Page or Card can show different data to different users without any modification."
        }
    },

    "productUpdates": {
        "backLabel": "Back",
        "noContentLabel": "There is no content",
        "nextLabel": "Next",
        "prevLabel": "Previous",
        "title": "Product updates"
    },

    "blog": {
        "backLabel": "Back",
        "noContentLabel": "There is no content",
        "nextLabel": "Next",
        "prevLabel": "Previous",
        "title": "Biuwer Blog",
        "postedBy": "Posted by",
        "category": {
            "product": "Product",
            "dataVisualization": "Data Visualization",
            "dataDrivenGrowth": "Data Driven Growth",
            "dataUseCase": "Data Use Case",
            "businessIntelligence": "Business Intelligence",
            "biuwer": "Biuwer"
        },
        "readMore": "Read more"
    },

    "footer": {
        "links": {
            "product": "Product",
            "services": "Services",
            "pricing": "Pricing",
            "blog": "Blog",
            "docs": "Documentation",
            "company": "Company",
            "contact": "Contact"
        },
        "builtWith": "Built with",
        "by": "by",
        "connectWithUs": "Connect with us",
        "trademarkMessage": "Biuwer Analytics S.L. - All Rights Reserved.",
        "termsOfService": "Terms of Service",
        "privacyPolicy": "Privacy Policy",
        "cookiePolicy": "Cookie Policy",
        "supportedBy": "Supported by",
        "supportedByMessageXpande": "Biuwer Analytics, S.L. has been a beneficiary of the European Regional Development Fund whose objective is to improve the competitiveness of SMEs and thanks to which it has launched an International Digital Marketing Plan with the aim of improving its online positioning in foreign markets during the year 2023. To this end, it has had the support of the XPANDE DIGITAL Program of the Chamber of Commerce of Murcia. A way of doing Europe."
    },
    "cookiesControl":{
        "buttonTextAccept":"Accept all",
        "buttonTextDecline":"Decline",
        "messageText":"We use cookies to improve the user experience, to provide social media features, personalise content and ads and analyze website traffic. For these reasons, we may share your site usage data with our analytics partners. By clicking “Accept all,” you consent to store on your device all the technologies described in our",
        "linkText":"Cookie Policy",
    },
    "page404":{
        "pageNotFound":"Sorry! We can’t find that page.",
        "message1":"Either it has been retired or we’re busy fixing it.",
        "message2":"Let’s go somewhere more useful instead.",
        "message3":"Click through one of these and take a look:",
        "link1":"Home",
        "link2":"Back"
    }
};

export default translationStrings;
